import { ZapOff } from 'lucide-react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/components/common/button'
import { StromConsultant } from 'src/components/ai/strom-consultant'
import { StromDeveloper } from 'src/components/ai/strom-developer'
import { StromMeeting } from 'src/components/ai/strom-meeting'
import { useEffect, useRef, useState } from 'react'
import Mixpanel from 'src/lib/mixpanel'

export default function AI() {
  const { t } = useTranslation()
  const [rendering, isRendering] = useState<boolean>(false)
  const [renderingJob, runRenderingJob] = useState<NodeJS.Timeout>()

  const stromDevRef = useRef(null)

  const triggerGenerate = () => {
    console.log('triggerGenerate index')
    if (stromDevRef.current) {
      stromDevRef.current.triggerGenerate() // Ensure this function is defined in StromDev
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div
        className="flex flex-grow"
        style={{
          backgroundSize: '40px 40px',
          backgroundImage: 'radial-gradient(#474747 1px, transparent 0)',
          backgroundPosition: '-5px -5px',
        }}
      >
        <div className="flex basis-1/3 2xl:basis-1/4 p-3 flex-row overflow-hidden">
          <StromConsultant rendering={rendering} triggerGenerate={triggerGenerate} />
        </div>
        <div className="flex basis-2/3 2xl:basis-3/4 py-3 pr-3 flex-col overflow-hidden">
          <StromDeveloper ref={stromDevRef} />
        </div>
      </div>

      {/* <div className="w-full basis-3/4 p-4 place-content-center">
        <div className="slds-path">
          <div className="slds-grid slds-path__track">
            <div className="slds-grid slds-path__scroller-container">
              <div className="slds-path__scroller">
                <div className="slds-path__scroller_inner">
                  <ul
                    className="slds-path__nav"
                    role="listbox"
                    aria-orientation="horizontal"
                    aria-labelledby="slds-path__stage-name"
                  >
                    <li className="slds-path__item slds-is-complete" role="presentation">
                      <a
                        aria-selected="false"
                        className="slds-path__link"
                        href="#"
                        id="path-6"
                        role="option"
                        tabindex="-1"
                      >
                        <span className="slds-path__stage">
                          <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                          </svg>
                          <span className="slds-assistive-text">Stage Complete</span>
                        </span>
                        <span className="slds-path__title">Contacted</span>
                      </a>
                    </li>
                    <li className="slds-path__item slds-is-complete" role="presentation">
                      <a
                        aria-selected="false"
                        className="slds-path__link"
                        href="#"
                        id="path-7"
                        role="option"
                        tabindex="-1"
                      >
                        <span className="slds-path__stage">
                          <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                          </svg>
                          <span className="slds-assistive-text">Stage Complete</span>
                        </span>
                        <span className="slds-path__title">Open</span>
                      </a>
                    </li>
                    <li className="slds-path__item slds-is-complete" role="presentation">
                      <a
                        aria-selected="false"
                        className="slds-path__link"
                        href="#"
                        id="path-8"
                        role="option"
                        tabindex="-1"
                      >
                        <span className="slds-path__stage">
                          <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                          </svg>
                          <span className="slds-assistive-text">Stage Complete</span>
                        </span>
                        <span className="slds-path__title">Unqualified</span>
                      </a>
                    </li>
                    <li className="slds-path__item slds-is-current slds-is-active" role="presentation">
                      <a
                        aria-selected="true"
                        className="slds-path__link"
                        href="#"
                        id="path-9"
                        role="option"
                        tabindex="0"
                      >
                        <span className="slds-path__stage">
                          <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                          </svg>
                          <span className="slds-assistive-text">Current Stage:</span>
                        </span>
                        <span className="slds-path__title">Nurturing</span>
                      </a>
                    </li>
                    <li className="slds-path__item slds-is-incomplete" role="presentation">
                      <a
                        aria-selected="false"
                        className="slds-path__link"
                        href="#"
                        id="path-10"
                        role="option"
                        tabindex="-1"
                      >
                        <span className="slds-path__stage">
                          <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                          </svg>
                        </span>
                        <span className="slds-path__title">Closed</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="slds-grid slds-path__action">
              <span id="slds-path__stage-name" className="slds-path__stage-name">
                Stage: Unqualified
              </span>
              <button className="slds-button slds-button_brand slds-path__mark-complete">
                <svg className="slds-button__icon slds-button__icon_left" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                </svg>
                Mark Status as Complete
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}
