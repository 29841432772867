import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  DatePicker,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  Progress,
  Slider,
  Textarea,
  useRadio,
  VisuallyHidden,
  cn,
  RadioGroup,
  Spinner,
  ScrollShadow,
  Chip,
} from '@nextui-org/react'

import StepWizard from 'react-step-wizard'
import './StepWizard.css'

import { Stepper, Step } from 'react-form-stepper'
import { parseDate, CalendarDate } from '@internationalized/date'

import dayjs from 'dayjs'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useSocketContextData } from 'src/context/socket'
import { useNavigate } from 'react-router-dom'

import Mixpanel from 'src/lib/mixpanel'

const inActiveButtonClass = 'bg-gray-300 text-default-500'
const activeButtonClass = 'text-white bg-gray-500 rounded-xl'

export const CustomRadio = (props) => {
  const {
    Component,
    children,
    isSelected,
    description,
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    getControlProps,
  } = useRadio(props)

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        'group inline-flex items-center hover:opacity-70 active:opacity-50 justify-between flex-row-reverse tap-highlight-transparent',
        'max-w-[300px] cursor-pointer border-2 border-default rounded-lg gap-4 p-2',
        'data-[selected=true]:border-secondary',
      )}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <span
        {...getWrapperProps()}
        style={
          isSelected
            ? {
                borderColor: '#65d0ca',
              }
            : {}
        }
      >
        <span
          {...getControlProps()}
          style={
            isSelected
              ? {
                  backgroundColor: '#65d0ca',
                }
              : {}
          }
        />
      </span>
      <div {...getLabelWrapperProps()}>
        {children && <span {...getLabelProps()}>{children}</span>}
        {description && <span className="text-small text-foreground opacity-70">{description}</span>}
      </div>
    </Component>
  )
}

function Step1(props) {
  const { data } = props
  const [documentLength, setDocumentLength] = useState<string>(data?.documentLength ?? 'MEDIUM')
  const [documentType, setDocumentType] = useState<string>(data?.documentType ?? 'URD')
  const [clientName, setClientName] = useState<string>(data?.clientName ?? '')
  const [title, setProjectTitle] = useState<string>(data?.title ?? '')
  const divRef = useRef<any>(null)
  useEffect(() => {
    const element = document.getElementById('document-wrap-container')
    if (element) {
      element.scrollTop = 0
    }
  }, [])

  return (
    <div className="flex flex-col gap-10" ref={divRef}>
      <div className="flex flex-col">
        <h2 className={'text-2xl pb-5'}>Project details</h2>
        <div className={'flex flex-row justify-between gap-5'}>
          <Input
            variant={'bordered'}
            label="Client name"
            placeholder={'e.g. ACME Inc. '}
            required
            classNames={{
              label: 'text-secondary',
            }}
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <Input
            variant={'bordered'}
            label="Project title"
            placeholder={'e.g. Salesforce Sales Cloud integration'}
            required
            classNames={{
              label: 'text-secondary',
            }}
            value={title}
            onChange={(e) => setProjectTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-2xl pb-5'}>Type of document</h2>
        <div className={'flex flex-row justify-evenly gap-5'}>
          <div
            className={`flex flex-col flex-1 cursor-pointer justify-evenly border-1 rounded-xl text-center p-5 ${
              documentType === 'URD' ? 'bg-teal-100' : ''
            }`}
            onClick={() => setDocumentType('URD')}
          >
            <h3 className={'text-2xl font-bold'}>URS</h3>
            <span className={'text-default-500 font-light text-sm'}>User Requirements Specifications/Document</span>
            <p className={'pt-5 pb-5 font-small'}>
              Describes the needs and expectations of the user, outlining functional and technical requirements for a
              system or product.
            </p>
            <Button
              variant={documentType === 'URD' ? 'solid' : 'bordered'}
              color={'secondary'}
              isDisabled={false}
              size={'md'}
              className={'pl-10 pr-10'}
              onClick={() => setDocumentType('URD')}
            >
              {documentType === 'URD' ? 'Selected' : 'Select'}
            </Button>
          </div>
          <div
            className={`flex flex-col flex-1 cursor-pointer justify-evenly border-1 rounded-xl text-center p-5 ${
              documentType === 'PROPOSAL' ? 'bg-teal-100' : ''
            }`}
            onClick={() => setDocumentType('PROPOSAL')}
          >
            <div>
              <h3 className={'text-2xl font-bold'}>Proposal</h3>
              <span className={'text-default-500 font-light text-sm'}>Proposal & Offer</span>
            </div>
            <p className={'pt-5 pb-5 font-small'}>
              A formal offer to solve a problem or deliver a project, detailing the approach, timeline, and estimated cost.
            </p>
            <Button
              variant={documentType === 'PROPOSAL' ? 'solid' : 'bordered'}
              color={'secondary'}
              isDisabled={false}
              size={'md'}
              className={'pl-10 pr-10'}
              onClick={() => setDocumentType('PROPOSAL')}
            >
              {documentType === 'PROPOSAL' ? 'Selected' : 'Select'}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-2xl pb-5'}>Length & formality of document</h2>
        <div className={'p-1 rounded-xl w-fit bg-gray-300'}>
          <Button
            size={'sm'}
            className={documentLength === 'SHORT' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('SHORT')}
          >
            Short & informal
          </Button>
          <Button
            size={'sm'}
            className={documentLength === 'MEDIUM' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('MEDIUM')}
          >
            Medium & semi-formal
          </Button>
          <Button
            size={'sm'}
            className={documentLength === 'LONG' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('LONG')}
          >
            Long & formal
          </Button>
        </div>
      </div>
      <div className="flex justify-end gap-5">
        <Button color="secondary" variant="bordered" onPress={() => props.onClose()} className={'pl-10 pr-10'}>
          Cancel
        </Button>
        <Button
          color={'secondary'}
          onPress={() => {
            props.submitData({
              clientName,
              title,
              documentLength,
              documentType,
            })
            props.nextStep()
          }}
          isDisabled={false}
          className={'pl-10 pr-10'}
          style={{
            backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
          }}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

function Step2(props) {
  const { data } = props
  const [description, setDescription] = useState<string>(data?.description ?? '')
  const [scopeOfProject, setScopeOfProject] = useState<string>(data?.scope ?? '')
  const [keyDeliverables, setKeyDeliverable] = useState<string>(data?.keyDeliverables ?? '')
  const progress = useMemo(() => Math.min(((description.split(' ').length - 1) / 20) * 100, 100), [description])
  const variant = useMemo(() => (progress < 50 ? 'danger' : progress < 90 ? 'warning' : 'success'), [progress])
  useEffect(() => {
    const element = document.getElementById('document-wrap-container')
    if (element) {
      element.scrollTop = 0
    }
  }, [])

  // Mixpanel tracking for doc wizard step
  useEffect(() => {
    Mixpanel.track("Document wizard step completed", {
      "step_number": 1,
      "step_name": "Project info",
    });
  }, []);
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col">
        <h2 className={'text-2xl'}>Project overview</h2>
        <p className="pt-0 pb-4 text-base leading-5 text-default-500">
          Provide a brief description of your project, including the main tasks and expected results.
        </p>
        <div className={'flex flex-col justify-between gap-2'}>
          <Textarea
            minRows={4}
            maxRows={6}
            variant="bordered"
            labelPlacement="outside"
            placeholder="e.g.: Developing a mobile app for personal finance management with features like expense tracking, budgeting, and bank integration. Deliverables include a functional app, user documentation, and post-launch support."
            className="w-full"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Progress color={variant} aria-label="Loading..." value={progress} className="w-full" />
          {progress > 0 && (
            <span className={`text-sm text-${variant}`}>
              {progress < 50 ? 'Weak overview!' : progress < 90 ? 'Keep going!' : 'Good overview!'}
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-2xl flex items-center'}>
          Scope, deliverables, and special requirements{' '}
          <Chip className="ml-2" variant="solid" color="secondary">
            Optional
          </Chip>
        </h2>
        <p className="pt-0 pb-4 text-base leading-5 text-default-500">
          Describe the key tasks you’ll perform, the main results you’ll deliver, and any specific requirements.
        </p>
        <div className={'flex flex-row justify-between gap-10'}>
          <Textarea
            minRows={4}
            maxRows={6}
            variant="bordered"
            labelPlacement="outside"
            placeholder="e.g.: UI design, backend development, implementing expense tracking. Deliverables are the mobile app and user documentation. The app must comply with GDPR and support English and Spanish."
            className="w-full"
            value={scopeOfProject}
            onChange={(e) => setScopeOfProject(e.target.value)}
          />
        </div>
      </div>
      {/* <div className={'p-10 pb-0'}>
        <h2 className={'text-2xl pb-5'}>Key deliverables</h2>
        <div className={'flex flex-row justify-between gap-10'}>
          <Textarea
            maxRows={3}
            minRows={3}
            variant="bordered"
            labelPlacement="outside"
            placeholder="Tell us a bit about you project"
            className="w-full"
            onChange={(e) => setKeyDeliverable(e.target.value)}
          />
        </div>
      </div> */}
      <div className="flex justify-end gap-5">
        <Button color="secondary" variant="bordered" onPress={() => props.previousStep()} className={'pl-10 pr-10'}>
          Previous
        </Button>
        <Button
          color={'secondary'}
          onPress={() => {
            props.submitData({
              description,
              scope: scopeOfProject,
              keyDeliverables,
            })
            props.nextStep()
          }}
          isDisabled={false}
          className={'pl-10 pr-10'}
          style={{
            backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
          }}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

function Step3(props) {
  const {formData: data} = props;
  const [budgetRange, setBudgetRange] = useState<number[]>(data?.budgetRange ?? [10000, 50000])
  const [paymentTerms, setPaymentTerms] = useState<string>(data?.paymentTerms ?? 'FIXED')
  const [specialRequirements, setSpecialRequirements] = useState<string>(data?.specialRequirements ?? '')
  const [loading, setLoading] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<CalendarDate>(data?.startDate ?? parseDate(dayjs().format('YYYY-MM-DD')))
  const [endDate, setEndDate] = useState<CalendarDate | null>(data?.endDate ?? null)
  const { activeWorkspace } = useWorkspaceContextData()
  const { io } = useSocketContextData()
  const navigate = useNavigate()
  useEffect(() => {
    const element = document.getElementById('document-wrap-container')
    if (element) {
      element.scrollTop = 0
    }
  }, [])

  // Mixpanel tracking for doc wizard step
  useEffect(() => {
    Mixpanel.track("Document wizard step completed", {
      "step_number": 2,
      "step_name": "Project scope",
    });
  }, []);

  return (
    <div className="flex flex-col gap-10">
      <div className="flex gap-5">
        <div className="flex flex-col">
          <h2 className={'text-2xl pb-5'}>Project start date</h2>
          <DatePicker
            variant={'bordered'}
            className="w-[250px]"
            //@ts-ignore
            value={startDate}
            //@ts-ignore
            onChange={(date) => {
              props.submitData({
                startDate: date,
              })
              setStartDate(date)
            }}
          />
        </div>
        <div className="flex flex-col">
          <h2 className={'text-2xl pb-5 flex items-center'}>
            End date{' '}
            <Chip className="ml-2" variant="solid" color="secondary">
              Optional
            </Chip>
          </h2>
          <DatePicker
            variant={'bordered'}
            className="w-[250px]"
            //@ts-ignore
            value={endDate}
            //@ts-ignore
            minValue={startDate}
            //@ts-ignore
            onChange={date => {
              props.submitData({
                endDate: date,
              })
              setEndDate(date)
            }}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className={'flex flex-col justify-between gap-2'}>
          <Slider
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Budget Range</span>
                <Chip className="ml-2" variant="solid" color="secondary">
                  Optional
                </Chip>
              </div>
            }
            step={1000}
            minValue={0}
            maxValue={100000}
            defaultValue={budgetRange}
            formatOptions={{ style: 'currency', currency: 'USD' }}
            classNames={{
              label: 'text-2xl pb-2',
              value: 'text-2xl pb-2',
            }}
            className="w-full"
            color={'secondary'}
            onChangeEnd={(range: number | number[]) => {
              setBudgetRange(range as number[])
              props.submitData({
                budgetRange: range,
              })
            }}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-2xl pb-5 flex items-center'}>
          Payment Terms{' '}
          <Chip className="ml-2" variant="solid" color="secondary">
            Optional
          </Chip>
        </h2>
        <div className={'flex flex-row justify-between gap-10'}>
          <RadioGroup orientation={'horizontal'} value={paymentTerms} onChange={(e) => {
            setPaymentTerms(e.target.value)
            props.submitData({
              paymentTerms: e.target.value,
            })
          }}>
            <CustomRadio value="FIXED">Fixed Price</CustomRadio>
            <CustomRadio value="HOURLY">Hourly</CustomRadio>
            <CustomRadio value="MILESTONE">Milestone-based</CustomRadio>
          </RadioGroup>
        </div>
      </div>
      {/* <div className="flex flex-col">
        <h2 className={'text-2xl pb-5 flex items-center'}>Special Requirements <Chip className="ml-2" variant="solid" color="secondary">Optional</Chip></h2>
        <p className="pt-0 pb-4 text-base leading-5 text-default-500">Include any specific project requirements that need special attention.</p>
        <div className={'flex flex-row justify-between gap-10'}>
          <Textarea
            minRows={3}
            maxRows={5}
            variant="bordered"
            labelPlacement="outside"
            placeholder="e.g.: “The app must comply with GDPR regulations, and support English and Spanish languages.”"
            className="w-full"
            onChange={(e) => setSpecialRequirements(e.target.value)}
          />
        </div>
      </div> */}
      <div className="flex justify-end gap-5">
        <Button color="secondary" variant="bordered" onPress={() => props.previousStep()} className={'pl-10 pr-10'}>
          Previous
        </Button>
        <Button
          color={'secondary'}
          onPress={async () => {
            const formData = {
              ...props.formData,
              minRange: budgetRange?.[0],
              maxRange: budgetRange?.[1],
              specialRequirements,
              paymentTerms,
              startDate: dayjs(startDate.toString()).format('YYYY-MM-DD'),
              endDate: endDate ? dayjs(endDate.toString()).format('YYYY-MM-DD') : null,
            }
            setLoading(true)
            try {
              const res = await io.emitWithAck('createUrs', {
                workspaceUUID: activeWorkspace,
                token: localStorage.getItem('accessToken'),
                ...formData,
              })
              navigate(`/urs/${res.uuid}`)
              
              // Mixpanel tracking for doc wizard step
              Mixpanel.track("Document wizard step completed", {
                "step_number": 3,
                "step_name": "Budget & terms"
              });

              Mixpanel.track("Document created", {
                "document_id": res.uuid,
              });
            } catch (error) {
              console.error('Document creation failed', error)
              Mixpanel.track("Document creation error", {
                "error_message": error,
              });
            } finally {
              setLoading(false)
            }
          }}
          isDisabled={false}
          className={'pl-10 pr-10'}
          style={{
            backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
          }}
        >
          Generate
        </Button>
        {/* <Button
          color={'secondary'}
          onPress={async () => {
            const formData = {
              ...props.formData,
              minRange: budgetRange?.[0],
              maxRange: budgetRange?.[1],
              specialRequirements,
              paymentTerms,
              startDate: dayjs(startDate.toString()).format('YYYY-MM-DD'),
              endDate: endDate ? dayjs(endDate.toString()).format('YYYY-MM-DD') : null,
            }
            setLoading(true)
            const res = await io.emitWithAck('createUrs', {
              workspaceUUID: activeWorkspace,
              token: localStorage.getItem('accessToken'),
              ...formData,
            })
            navigate(`/urs/${res.uuid}`)
            setLoading(false)
            //props.nextStep()
          }}
          isDisabled={false}
          className={'pl-10 pr-10'}
          style={{
            backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
          }}
        >
          Generate
        </Button> */}
      </div>
      {loading && (
        <div className={'fixed w-full h-full top-0 left-0'}>
          <div className={'bg-black/75 backdrop-blur-sm w-full h-full justify-center items-center flex gap-3'}>
            <Spinner size={'lg'} color="secondary" />
            <span className="text-white text-xl">Processing information…</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default function CreateUrdWindow(props: any) {
  const { isOpen, onClose } = props
  const [activeStep, setActiveStep] = useState(1)
  const [stepWizard, setStepWizard] = useState(null)
  const [formData, setFormData] = useState<any>({})
  const assignStepWizard = (instance) => {
    setStepWizard(instance)
  }
  const handleStepChange = (e) => {
    console.log('step change')
    console.log(e)
    setActiveStep(e.activeStep)
  }

  const customStepWizardTransitions = {
    enterRight: 'enter-right', // Class for entering from the right
    enterLeft: 'enter-left', // Class for entering from the left
    exitRight: 'exit-right', // Class for exiting to the right
    exitLeft: 'exit-left', // Class for exiting to the left
    intro: '', // Leave empty if no intro animation is needed
  }

  // @ts-ignore
  return (
    <Modal
      className={'no-scrollbar'}
      backdrop="blur"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="4xl"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody className={'scrollbar-hide px-2 md:px-20 pb-10'}>
              <ScrollShadow hideScrollBar size={100} id={'document-wrap-container'}>
                <Stepper
                  activeStep={activeStep}
                  //@ts-ignore
                  styleConfig={{
                    activeBgColor: '#71717A',
                    completedBgColor: '#65d0ca',
                    inactiveBgColor: '#71717A',
                  }}
                  //@ts-ignore
                  connectorStyleConfig={{
                    disabledColor: '#71717A',
                    activeColor: '#65d0ca',
                    completedColor: '#65d0ca',
                  }}
                >
                  <Step label="Project info" />
                  <Step label="Project scope" />
                  <Step label="Budget & terms" />
                </Stepper>
                <StepWizard isLazyMount={true} instance={assignStepWizard} onStepChange={handleStepChange}>
                  <Step1
                    title={'step 1'}
                    onClose={() => {
                      setFormData({})
                      onClose?.()
                    }}
                    data={formData}
                    submitData={(data) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        ...data,
                      }))
                    }}
                  />
                  <Step2
                    title={'step 2'}
                    onClose={onClose}
                    data={formData}
                    submitData={(data) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        ...data,
                      }))
                    }}
                  />
                  <Step3
                    title={'step 3'}
                    onClose={onClose}
                    formData={formData}
                    submitData={(data) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        ...data,
                      }))
                    }}
                  />
                </StepWizard>
              </ScrollShadow>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
