import { useEffect, useRef } from 'react'

export const CodePreview = ({ html, css }: { html: string | undefined; css: string | undefined }) => {
  const previewRef = useRef<HTMLDivElement>(null)
  const htmlRef = useRef<HTMLDivElement>(null)
  const cssRef = useRef<HTMLDivElement>(null)
  const domRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    console.log('Preview rerendered', html)
    console.log(previewRef.current?.innerText)
    console.log(htmlRef.current?.innerText)
    console.log(cssRef.current?.innerText)

    if (domRef.current && domRef.current) {
      domRef.current.innerHTML = `<div id="previewWrapper"><style>${css || ''}</style>${html || ''}</div>`
    }

    console.log(domRef.current?.innerHTML)
  }, [html, css])

  return (
    <div className="absolute inset-0 flex justify-center">
      <div className="absolute inset-0 overflow-hidden rounded-b-lg bg-black">
        <div ref={htmlRef} className="codeHtml hidden">
          {html}
        </div>
        <div ref={cssRef} className="codeCss hidden">
          {css}
        </div>
        <div ref={domRef} className="codeDom">
          Generating the preview
        </div>
      </div>
    </div>
  )
}
