import type {PricingFeatures} from "./pricing-comparison-types";

import {TiersEnum} from "./pricing-types";

const features: PricingFeatures = [
  {
    title: "AI configurations",
    items: [
      {
        title: "Editors included",
        tiers: {
          [TiersEnum.Free]: "1",
          [TiersEnum.Professional]: "1",
          [TiersEnum.Business]: "Unlimited",
          [TiersEnum.Enterprise]: "Unlimited",
        },
        helpText:
          "Enjoy over 1,000+ screens uploaded every week. Get notified via email whenever new screens are added.",
      },
      {
        title: "Project drafts",
        tiers: {
          [TiersEnum.Free]: "Unlimited",
          [TiersEnum.Professional]: "Unlimited",
          [TiersEnum.Business]: "Unlimited",
          [TiersEnum.Enterprise]: "Unlimited",
        },
        helpText:
          "Enjoy over 1,000+ screens uploaded every week. Get notified via email whenever new screens are added.",
      },
      {
        title: "Export projects to Salesforce",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: true,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText: "Access to latest screenshots of an app cataloged by expert curators.",
      },
    ],
  },
  {
    title: "Features",
    items: [
      {
        title: "Premium templates",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: true,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText: "Save apps, screens or flows into collections for later viewing.",
      },
      {
        title: "Advanced anyalytics",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: true,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText: "Quickly copy screens into your clipboard to be pasted into other tools.",
      },
      {
        title: "Version history",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: "7 days",
          [TiersEnum.Business]: "Unlimited",
          [TiersEnum.Enterprise]: "Unlimted",
        },
        helpText: "Download screens as PNG.",
      },
      {
        title: "Team collaboration features",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText:
          "Easily batch-download multiple screens at one go from apps, flows, your collections and more.",
      },
      {
        title: "Audit logging",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText:
          "Easily batch-download multiple screens at one go from apps, flows, your collections and more.",
      },
      {
        title: "Custom reports and analytics",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText:
          "Easily batch-download multiple screens at one go from apps, flows, your collections and more.",
      },
      {
        title: "Unified admin and billing",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText:
          "Easily batch-download multiple screens at one go from apps, flows, your collections and more.",
      },
    ],
  },
  {
    title: "Collaboration",
    items: [
      {
        title: "Team members",
        tiers: {
          [TiersEnum.Free]: "Just you",
          [TiersEnum.Pro]: "Just you",
          [TiersEnum.Team]: "Unlimited",
        },
        helpText: "Collaborate with other users in a team.",
      },
      {
        title: "Team collections",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Pro]: false,
          [TiersEnum.Team]: true,
        },
        helpText: "Create collections shared across your team.",
      },
      {
        title: "Team administration",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Pro]: false,
          [TiersEnum.Team]: true,
        },
        helpText:
          "Add or remove members from your team to manage access to membership and collections.",
      },
      {
        title: "Flexible seat-based licensing",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Pro]: false,
          [TiersEnum.Team]: true,
        },
        helpText:
          "Membership licenses are purchased by seats, which can be provisioned to or removed from users.",
      },
    ],
  },
  {
    title: "Security & Access",
    items: [
      {
        title: "SAML Single Sign-On (SSO)",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: false,
          [TiersEnum.Enterprise]: true,
        },
        helpText:
          "Access through Okta, ADFS, Azure, Onelogin, or your own SAML identity provider (IdP).",
      },
      {
        title: "SCIM user provisioning",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: false,
          [TiersEnum.Enterprise]: true,
        },
        helpText:
          "Sync user directories with Okta, Azure AD, Onelogin, or your own SCIM identity provider (IdP).",
      },
    ],
  },
  {
    title: "Support",
    items: [
      {
        title: "Help center",
        tiers: {
          [TiersEnum.Free]: true,
          [TiersEnum.Professional]: true,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText:
          "Browse our articles in our knowledge base to find answers to your questions regarding the platform.",
      },
      {
        title: "Email support",
        tiers: {
          [TiersEnum.Free]: "Best effort basis",
          [TiersEnum.Professional]: true,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText: "Get help via email.",
      },
      {
        title: "Priority email support",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: true,
          [TiersEnum.Enterprise]: true,
        },
        helpText: "Get help via email.",
      },
      {
        title: "Dedicated account manager",
        tiers: {
          [TiersEnum.Free]: false,
          [TiersEnum.Professional]: false,
          [TiersEnum.Business]: false,
          [TiersEnum.Enterprise]: true,
        },
        helpText: "Get help via email.",
      },
    ],
  },
];

export default features;
