import React, { useCallback, useEffect, useState } from 'react'
import { Button, cn, ScrollShadow, Textarea } from '@nextui-org/react'
import { RefreshCcwIcon, TrashIcon, UploadCloud } from 'lucide-react'
import { useDropzone } from 'react-dropzone'
import api from 'src/lib/api'

export default function DocumentSettings({ isSettingsOpen, close }) {
  const [footer, setFooter] = useState<string>('')
  const [header, setHeader] = useState<string>('')
  const [logo, setLogo] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [loading, setLoading] = useState<boolean>(false)
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0])
    if (acceptedFiles[0]) {
      const preview = URL.createObjectURL(acceptedFiles[0])
      setLogo(preview)
    }
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept specific file types if needed
    maxFiles: 1, // Limit to one file at a time
  })
  const loadMyConfig = async () => {
    const res = await api.get('/urs/config')
    if (res.data) {
      setFooter(res.data?.footer ?? '')
      setHeader(res.data?.header ?? '')
      setLogo(res.data?.logo ?? '')
    }
  }
  const applyConfig = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('footer', footer)
    formData.append('header', header)
    if (file) {
      formData.append('file', file)
    }
    const res = await api.post('/urs/config', formData)
    setLoading(false)
    close()
  }
  const deleteFile = async () => {
    setLogo('')
    const res = await api.post('/urs/config', {
      logo: '',
    })
    setLoading(false)
  }

  useEffect(() => {
    loadMyConfig()
  }, [])
  return (
    <ScrollShadow
      className={cn(
        isSettingsOpen ? 'w-1/3 z-30' : 'w-0 -z-10',
        'overflow-auto absolute right-0 top-0 p-4 bg-white h-full border border-divider border-l-1 border-y-0 border-r-0 transition-all duration-500 ease-in-out',
      )}
      hideScrollBar
      size={100}
      id="settings"
    >
      <div className="flex flex-col gap-2">
        <h2 className={'text-xl font-bold'}>Document Settings</h2>
        <div className={'flex flex-col gap-2 mt-2'}>
          <h3 className={'text-medium font-medium'}>Custom header</h3>
          <Textarea
            variant={'bordered'}
            placeholder={'e.g.\nACME Inc.\nAddress: 123 Market St, Springfield, CA 90210\nPhone: (555) 123-4567 | Email: contact@acme.com'}
            classNames={{
              inputWrapper:
                'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
              input: 'font-medium',
            }}
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            minRows={5}
            maxRows={5}
          />
        </div>
        <div className={'flex  flex-col gap-2 mt-2'}>
          <h3 className={'text-medium font-medium'}>Custom footer</h3>
          <Textarea
            variant={'bordered'}
            placeholder={'e.g.\n© 2024 ACME Inc. All rights reserved.\nEmpowering progress, one solution at a time.'}
            classNames={{
              inputWrapper:
                'border-secondary group-data-[focus=true]:border-secondary data-[hover=true]:border-secondary',
              input: 'font-medium',
            }}
            value={footer}
            onChange={(e) => setFooter(e.target.value)}
            minRows={5}
            maxRows={5}
          />
        </div>
        <div {...getRootProps({ className: ' flex  flex-col gap-2 mt-2' })}>
          <input {...getInputProps()} />
          <h3 className={'text-medium font-medium'}>Custom logo</h3>
          <div className={'flex flex-col gap-1 justify-center items-center p-8 border-1 border-secondary rounded-xl'}>
            {logo ? (
              <>
                <img src={logo} className={'w-[120px]'} />
                <div className={'flex gap-2'}>
                  <Button
                    variant="solid"
                    color={'secondary'}
                    className={'text-white text-medium font-medium p-6 pl-12 pr-12 mt-2'}
                    onClick={open}
                  >
                    <RefreshCcwIcon />
                    Select file
                  </Button>
                  <Button
                    variant="bordered"
                    color={'secondary'}
                    clas sName={'text-white font-medium p-6 pl-12 pr-12 mt-2'}
                    onClick={deleteFile}
                  >
                    <TrashIcon />
                    Delete File
                  </Button>
                </div>
              </>
            ) : (
              <>
                <UploadCloud size={60} color={'#65d0ca'} className={'mb-2'} />
                <p className={'font-medium text-xl'}>Select a file or drag and drop here</p>
                <span className={'text-sm font-regular'}>JPG or PNG, file size no more than 10MB</span>
                <Button
                  variant="solid"
                  color={'secondary'}
                  className={'text-white text-medium font-medium p-6 pl-12 pr-12 mt-2'}
                  onClick={open}
                >
                  Select file
                </Button>
              </>
            )}
          </div>
        </div>

        <div className={'flex flex-col gap-2 mt-2'}>
          <Button
            variant="bordered"
            color={'secondary'}
            className={'text-medium font-medium p-6 pl-12 pr-12 mt-2'}
            onClick={applyConfig}
          >
            Save
          </Button>
          <p className="text-tiny">Applies your custom header, footer, and logo to all documents.</p>
        </div>
      </div>
    </ScrollShadow>
  )
}
