import React, { useEffect, useCallback, useState, forwardRef, useImperativeHandle } from 'react'
import debounce from 'lodash.debounce'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'

import { Color } from '@tiptap/extension-color'
import { useEditor, EditorContent } from '@tiptap/react'
import { Markdown } from 'tiptap-markdown'
import Underline from '@tiptap/extension-underline'
import { getHierarchicalIndexes, TableOfContents } from '@tiptap-pro/extension-table-of-contents'
import { Dropcursor } from '@tiptap/extension-dropcursor'
import { useSocketContextData } from 'src/context/socket'
import { Spinner, Tooltip, ScrollShadow } from '@nextui-org/react'
import { GripVertical } from 'lucide-react'

import Mixpanel from 'src/lib/mixpanel'

import { Icon } from '@iconify/react'

import '@milkdown/crepe/theme/common/style.css'
import '@milkdown/theme-nord/style.css'
import '@milkdown/crepe/theme/frame.css'

import './editor-styles.css'
import SmartTextMenu from 'src/pages/ursPage/extensions/SmartTextMenu'

export const TipTapEditor = forwardRef(
  (
    {
      markdown,
      ursUUID,
    }: {
      markdown: string
      ursUUID?: string
    },
    ref,
  ) => {
    const [tableContent, setTableContent] = useState<any[]>([])
    const extensions = [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      Underline,
      TextStyle,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: true,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Markdown,
      TableOfContents.configure({
        getIndex: getHierarchicalIndexes,
        onUpdate(content) {
          setTableContent(content)
        },
      }),
      Dropcursor.configure({
        color: 'blue',
        width: 3,
        class: 'prosemirror-dropcursor',
      }),
    ]
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [currentHead, setCurrentHead] = useState<number>(0)
    const debouncedUpdate = useCallback(
      debounce(async (editor) => {
        if (ursUUID) {
          setSaving(true)
          const content = editor.storage.markdown.getMarkdown()
          await io.emitWithAck('updateDocument', {
            content,
            ursUUID,
            token: localStorage.getItem('accessToken'),
          })
          setSaving(false)
          setSaved(true)
          setTimeout(() => {
            setSaved(false)
          }, 5000)
        }
      }, 500), // Delay of 500ms
      [],
    )
    const editor = useEditor({
      // @ts-ignore
      extensions,
      content: markdown,
      onUpdate: ({ editor }) => {
        // Trigger the debounced update
        debouncedUpdate(editor)
      },
    })
    useEffect(() => {
      if (editor) {
        editor.commands?.setContent(markdown)
        editor.commands?.insertContent(' ')
      }
    }, [markdown, editor])
    useEffect(() => {
      return () => {
        if (editor) {
          editor.destroy() // Clean up the editor on unmount
        }
        // Cancel the debounce on unmount to prevent memory leaks
        debouncedUpdate.cancel()
      }
    }, [editor, debouncedUpdate])
    const { io } = useSocketContextData()

    useImperativeHandle(
      ref,
      () => {
        return {
          getMarkdown: () => {
            return editor?.storage.markdown.getMarkdown()
          },
          setMarkdown: (content: string) => {
            editor?.commands?.setContent(content)
          },
        }
      },
      [editor],
    )
    if (!editor) {
      return null
    }
    const scrollToItem = (id: string) => {
      const headingElement = document.getElementById(id)
      console.log(headingElement, id)
      if (headingElement) {
        headingElement.scrollIntoView({ behavior: 'smooth' })

        // Extract text from <strong> element if it exists
        const strongElement = headingElement.querySelector('strong');
        const headingText = strongElement ? strongElement.textContent : 'Unknown';

        // Track the scroll event for Table of Content navigation
        Mixpanel.track("Table of content item clicked", {
          "heading_text": headingText,
          "element_id": id,
        });

        headingElement.classList.add('highlight')
        setTimeout(() => {
          headingElement.classList.remove('highlight') // Remove highlight after 2 seconds
        }, 2000)
      }
    }

    // FUNCTION: Smart Text Menu Opener
    // Opens 'Smart Text Menu' on document automatically
    // so that we show users intuitively that there
    // is more to explore. Should be opened only once
    // per registered user, for the very first document.
    // ***********************************************
    useEffect(() => {
      if (editor) {
        // Show the Bubble Menu after 2 seconds by creating a selection
        const timer = setTimeout(() => {
          const { state, view } = editor
          const { doc } = state
          try {
            const userString = localStorage.getItem('user')
            const user = JSON.parse(userString)
            if (!!!user.isNew) {
              return
            }
            user.isNew = false
            localStorage.setItem('user', JSON.stringify(user))
          } catch(err) {
            console.log('Error: ', err)
          }
          // Ensure the positions are valid by checking the document size
          const startPos = 0
          const endPos = Math.min(10, doc.content.size) // Limit end position to document size

          if (startPos < doc.content.size) {
            view.dispatch(state.tr.setSelection(state.selection.constructor.create(state.doc, startPos, endPos)))

            // Optionally focus the editor if needed
            view.focus()

            // Wait for the menu to become visible and then click the desired element
            setTimeout(() => {
              const menuItem = document.getElementById('smart-text-menu-ai-item')
              if (menuItem) {
                menuItem.click()
              }
            }, 500) // Adjust delay as needed to ensure menu visibility
          }
        }, 500)

        return () => clearTimeout(timer) // Clear timer on component unmount
      }
    }, [editor])

    return (
      <div className={'crepe relative'}>
        <div className={'toc-wrapper'}>
          <div className={'fixed'}>
            <div className={'toc'}>
              <div className={'toc-mini p-2'}>
                {tableContent
                  .filter((item) => item.level < 3)
                  .map((item) => (
                    <div key={item.label} className="toc-item-mini toc-light">
                      ▃▃
                    </div>
                  ))}
              </div>
              <div className="toc-list flex flex-col shadow-md rounded-xl h-[300px] p-4 text-default overflow-y-scroll bg-white w-[250px] scrollbar-hide opacity-0 transition-opacity duration-300 hover:opacity-100 hover:block hidden">
                <ScrollShadow className="grow mb-3" hideScrollBar size={40}>
                  {tableContent.map((item, index) => (
                    <div
                      onClick={() => scrollToItem(item.id)}
                      title={item.textContent}
                      className="toc-item cursor-pointer text-default-700 text-xs p-2 rounded-lg hover:bg-gray-100 truncate"
                    >
                      {item.textContent}
                    </div>
                  ))}
                </ScrollShadow>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 pb-2 bg-black/70 backdrop-blur-sm pl-5 pr-5 pt-2 rounded-xl notification transition-opacity duration-100 ${
            saving ? 'loading' : saved ? 'saved' : ''!
          }`}
        >
          {saving && (
            <div className="flex gap-2 items-center">
              <Spinner size="sm" color="white" />
              <p>Saving changes</p>
            </div>
          )}
          {saved && (
            <div className="flex gap-2 items-center">
              <Icon icon="lucide:check" />
              <p className="thumbs-up">Changes saved</p>
            </div>
          )}
        </div>
        <div className={'tiptap'}>
          <DragHandle
            editor={editor}
            tippyOptions={{
              placement: 'left-start',
            }}
          >
            <div style={{ padding: 0, margin: 0 }}>
              <Tooltip content="Drag to move" placement="bottom" offset={-7} size="sm" radius="sm">
                <div className="p-3 bg-white">
                  <GripVertical color={'#D3D1CB'} />
                </div>
              </Tooltip>
            </div>
          </DragHandle>
          <EditorContent editor={editor} content={markdown}>
            {editor && <SmartTextMenu editor={editor} ursUUID={ursUUID} />}
          </EditorContent>
        </div>
      </div>
    )
  },
)
