import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from '@nextui-org/react'
import api from 'src/lib/api'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'

import AccountSetting from './account-setting'
import BillingSetting from './billing-setting'

export default function Settings() {
  const { t } = useTranslation()

  const [profile, setPorfile] = useState<any>({})
  const [isVisible, setIsVisible] = React.useState(false)

  const toggleVisibility = () => setIsVisible(!isVisible)
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setPorfile(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setPorfile(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])

  const { tab } = useParams()
  const [selected, setSelected] = React.useState(tab)

  const updateProfile = () => {
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setPorfile(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex flex-col w-full h-screen p-3 overflow-y-auto">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Settings</h1>
              <p className="text-small text-default-700 lg:text-medium">
                Configure global preferences, payment options, and system settings.
              </p>
            </div>
          </header>
        </div>

        {/*  Tabs */}
        <div className="flex flex-col gap-3 w-full h-full px-2 2xl:px-8 max-w-[750px]">
          <Tabs
            fullWidth
            aria-label="Settings"
            selectedKey={selected}
            onSelectionChange={setSelected}
            classNames={{
              base: 'mt-6',
              cursor: 'bg-content1 dark:bg-content1',
              panel: 'w-full p-0 pt-4',
            }}
          >
            <Tab key="account" title="Account">
              <AccountSetting />
            </Tab>
            {/* <Tab key="appearance" title="Appearance">
              <AppearanceSetting />
            </Tab> */}
            <Tab key="billing" title="Billing">
              <BillingSetting client={profile} loadUser={loadUser} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}
