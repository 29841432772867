import { Spinner } from '@nextui-org/react'
import React, {useEffect, useState} from 'react'
import { useSocketContextData } from 'src/context/socket'

import FeaturesCards from './features-cards'
import PromptInputWithBottomActions from './prompt-input-with-bottom-actions'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useNavigate } from 'react-router-dom'

export default function CreateMeetingBlock({ defaultOrg }: { defaultOrg?: string }) {
  const [textDescription, setTextDescription] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { io, isSocketConnected } = useSocketContextData()
  const [targetOrg, setTargetOrg] = useState<string | undefined>(defaultOrg)
  const { activeWorkspace } = useWorkspaceContextData()
  const navigate = useNavigate()
  const createTextMeeting = async () => {
    setLoading(true)
    const meetingUUID = await io.emitWithAck('createMeeting', { orgAlias: targetOrg, workspaceUUID: activeWorkspace })
    await io.emitWithAck('claimMeeting', {
      meetingUUID: meetingUUID,
      token: localStorage.getItem('accessToken'),
    })
    const summary = await io.emitWithAck('textMeeting', {
      language: 'en',
      meetingUUID: meetingUUID,
      content: textDescription,
    })
    localStorage.setItem('summary', summary)
    navigate(`/project/${meetingUUID}`)
  }
  const createFileMeeting = async (file: File) => {
    setLoading(true)
    const reader = new FileReader()
    reader.onload = async (event) => {
      const content = event.target.result.toString()
      const meetingUUID = await io.emitWithAck('createMeeting', { orgAlias: targetOrg, workspaceUUID: activeWorkspace })
      await io.emitWithAck('claimMeeting', {
        meetingUUID: meetingUUID,
        token: localStorage.getItem('accessToken'),
      })
      const summary = await io.emitWithAck('textMeeting', {
        language: 'en',
        meetingUUID: meetingUUID,
        content: content,
      })
      localStorage.setItem('summary', summary)
      navigate(`/project/${meetingUUID}`)
    } // desired file content
    reader.onerror = (error) => {
      setLoading(false)
    }
    reader.readAsText(file)
  }
  return (
    <>
      <div className="flex h-full w-full max-w-full flex-col gap-8 items-center justify-center">
        <div className="flex flex-col gap-3 w-full max-w-[1000px]">
          <div className="flex justify-center">
            <FeaturesCards />
          </div>
          <div className="flex flex-col gap-2 relative">
            <PromptInputWithBottomActions
              onChange={(e) => setTextDescription(e.target.value)}
              onTextSubmit={createTextMeeting}
              onFileSubmit={createFileMeeting}
              onTargetOrgChange={setTargetOrg}
              defaultOrg={defaultOrg}
            />
            <p className="px-2 text-tiny text-default-400">Enter a detailed description of your project.</p>
            {loading && (
              <div
                className="rounded-medium"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: '#0000009a',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Spinner size={'xl'} color={'secondary'} />
                <p className="px-2 text-tiny text-white">Generating project.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
