import api from 'src/lib/api'
import React, { useEffect, useState } from 'react'
import { AtSign, LayoutDashboard, Building } from 'lucide-react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
  Image,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ScrollShadow,
  Chip,
  Avatar,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spacer,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'
import { useSocketContextData } from 'src/context/socket'
import { useWorkspaceContextData } from 'src/context/workspace'

export default function Clients() {
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [clients, setClients] = useState<any>([])
  const [clientName, setClientName] = useState<string>('')
  const [clientURL, setClientURL] = useState<string>('')
  const [clientAlias, setClientAlias] = useState<string>('')
  const [opener, setOpener] = useState<Window | null>(null)

  const { activeWorkspace } = useWorkspaceContextData()
  const { io } = useSocketContextData()
  // Create new client modal and listen to "new" in the URL
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const gradients = [
    'bg-gradient-to-br from-[#FFB457] to-[#FF705B]',
    'bg-gradient-to-br from-[#34D399] to-[#3B82F6]',
    'bg-gradient-to-br from-[#FBBF24] to-[#F59E0B]',
    'bg-gradient-to-br from-[#10B981] to-[#065F46]',
    'bg-gradient-to-br from-[#A78BFA] to-[#7C3AED]',
    'bg-gradient-to-br from-[#F472B6] to-[#DB2777]',
    'bg-gradient-to-br from-[#60A5FA] to-[#2563EB]',
    'bg-gradient-to-br from-[#EC4899] to-[#BE185D]',
    'bg-gradient-to-br from-[#F87171] to-[#B91C1C]',
    'bg-gradient-to-br from-[#C084FC] to-[#9333EA]',
    'bg-gradient-to-br from-[#F97316] to-[#EA580C]',
    'bg-gradient-to-br from-[#2DD4BF] to-[#0891B2]',
    'bg-gradient-to-br from-[#4ADE80] to-[#16A34A]',
    'bg-gradient-to-br from-[#818CF8] to-[#6366F1]',
    'bg-gradient-to-br from-[#FDE68A] to-[#F59E0B]',
    'bg-gradient-to-br from-[#F9A8D4] to-[#E11D48]',
    'bg-gradient-to-br from-[#A3E635] to-[#65A30D]',
    'bg-gradient-to-br from-[#38BDF8] to-[#0284C7]',
    'bg-gradient-to-br from-[#FDBA74] to-[#FB923C]',
    'bg-gradient-to-br from-[#FB7185] to-[#E11D48]',
  ]

  const loadInstances = () => {
    api
      .get(`/orgs/${activeWorkspace}`)
      .then((res) => {
        setClients(res.data ?? [])
      })
      .catch((err) => {
        console.log('Clients error', err.message)
        // setClients(testClients)
      })
      .finally(() => setInitialized(true))
  }
  const handleClientNameChange = (e) => {
    const newValue = e.target.value
    setClientName(newValue)
    // Handle alias characters: no spaces, replace spaces with dashes, make it lowercase
    const formattedValue = newValue
      .replace(/\s+/g, '-') // Replace spaces with dashes
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9\-]/g, '') // Remove special characters, allowing only letters, numbers, and dashes
    setClientAlias(formattedValue)
  }
  const loginWithSalesForce = async () => {
    io.emit('createInstance', {
      alias: clientAlias,
      name: clientName,
      url: clientURL,
      token: localStorage.getItem('accessToken'),
      workspaceUUID: activeWorkspace,
    })
    /*const res = await api.post('/orgs/auth', {
      alias: clientAlias,
      name: clientName,
      url: clientURL,
    })
    const { authUri } = res.data
    if (authUri) {
      window.open(authUri, '_blank')
    }*/
  }
  const handleOpen = () => {
    onOpen()
  }
  useEffect(() => {
    console.log('Clients loading')
    loadInstances()
  }, [activeWorkspace])

  useEffect(() => {
    io?.on('startInstanceAuth', (data: any) => {
      const { authUri } = data
      if (authUri) {
        const op = window.open(authUri, '_blank', 'location=yes,height=450,width=800,scrollbars=yes,status=yes')
        setOpener(op)
      }
    })
    io?.on('closeAuthSuccess', (data: any) => {
      console.log('We should actually close this windows as we have got what we need', data)
      loadInstances()
      if (opener) {
        opener.close()
      }
      onClose()
    })
  }, [io])
  useEffect(() => {
    loadInstances()
  }, [activeWorkspace])

  useEffect(() => {
    if (window.location.pathname === '/clients/new') {
      handleOpen()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <Modal backdrop="blur" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="lg">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Create new connection</ModalHeader>
              <Spacer y={4} />
              <ModalBody>
                <div className="flex flex-col grow gap-7">
                  <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold">Create & connect</p>
                    <p className="text-base text-center">
                      Connect a Salesforce instance to the<br />workspace, either your own or from a client.
                    </p>
                  </div>
                  <div className="flex flex-col gap-3 items-center">
                    <Input
                      isRequired
                      type="text"
                      label="Salesforce instance name"
                      placeholder="ACME Inc."
                      className="max-w-xs"
                      onChange={handleClientNameChange}
                    />
                    <Input
                      type="url"
                      label="Company URL of instance (optional)"
                      placeholder="www.acme-inc.com"
                      className="max-w-xs"
                      onChange={(e) => setClientURL(e.target.value)}
                    />
                    <Input
                      isRequired
                      type="text"
                      label="Salesforce instance alias"
                      placeholder="acme-inc"
                      className="max-w-xs"
                      onChange={(e) => setClientAlias(e.target.value)}
                      value={clientAlias}
                    />
                  </div>
                </div>
              </ModalBody>
              <Spacer y={4} />
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="bordered"
                  startContent={<Icon icon="logos:salesforce" width={24} />}
                  onPress={loginWithSalesForce}
                >
                  Create & connect
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <div className="flex flex-col w-full h-screen p-3">
        <div className="flex w-full pt-8 pb-6 px-4 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">SFDC instances</h1>
              <p className="text-small text-default-400 lg:text-medium">
                Manage Salesforce instances, connections, and configurations.
              </p>
            </div>
            <Button
              onClick={() => handleOpen()}
              color="secondary"
              size="md"
              radius="md"
              startContent={<Icon className="flex-none" icon="solar:add-circle-linear" width={20} />}
            >
              Connect new instance
            </Button>
          </header>
        </div>

        {!Array.isArray(clients) || clients.length == 0 ? (
          <div className="flex flex-col items-center place-content-center grow">
            <Image
              src="/img/empty-state-clients.png"
              alt="no projects available"
              width={400}
              radius="none"
              className="mb-8"
            />
            <p className="text-2xl text-center font-semibold mb-2">No instance available</p>
            <p className="text-base text-center">
              Create your first instance by clicking <br />
              on the button at the top right
            </p>
          </div>
        ) : (
          <div className="flex gap-3 w-full max-w-[1000px] max-h-full px-4 2xl:px-8 overflow-y-auto">
            <ScrollShadow className="space-y-3" hideScrollBar size={100}>
              <div className="grid grid-cols-2 gap-4 grow max-h-full">
                {clients.map((client, index) => (
                  <Card className="p-1" isPressable onPress={() => navigate(`/instances/${client.alias}/projects`)}>
                    <CardHeader className="flex gap-3 text-left">
                      <Avatar
                        showFallback
                        name={client.name?.length > 0 ? client.name : client.alias}
                        icon={<Building />}
                        classNames={{
                          base: gradients[index],
                          icon: 'text-black/80',
                        }}
                        radius="md"
                        isBordered
                      />
                      <div className="flex flex-col">
                        <p className="text-md">{client.url?.length > 0 ? client.url : client.alias}</p>
                        <p className="text-small text-default-500">
                          {client.url?.length > 0 ? client.url : client.instanceUrl}
                        </p>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <p className="text-tiny uppercase font-bold">Latest project</p>
                      <small className="text-default-500 line-clamp-1">
                        {client.projects?.[0]?.title ??
                          client.projects?.[0]?.title?.substr(0, 120) ??
                          client.projects?.uuid}
                      </small>
                    </CardBody>
                    <CardFooter className="gap-2">
                      <Chip startContent={<AtSign size={18} />} variant="flat">
                        {client.alias}
                      </Chip>
                      <Chip startContent={<LayoutDashboard size={18} />} variant="flat">
                        {client.projects?.length === 1 ? '1 Project' : client.projects?.length + ' Projects'}
                      </Chip>
                    </CardFooter>
                  </Card>
                ))}
              </div>
            </ScrollShadow>
          </div>
        )}
      </div>
    </>
  )
}
