import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Button, Input, Link, Image, Spacer } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import api from 'src/lib/api'
import React, { useEffect, useState, useCallback } from 'react'
import Mixpanel from 'src/lib/mixpanel'
import { useNavigate } from 'react-router-dom'
import { useWorkspaceContextData } from 'src/context/workspace'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import {AxiosError} from "axios";
export default function Signup() {
  const { t } = useTranslation()

  const [firstname, setFirstName] = useState<string>('')
  const [lastname, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [accountType, setAccountType] = useState<string>('ourselves')
  const [error, setError] = useState<string>('')

  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const [isConfirmVisible, setIsConfirmVisible] = React.useState<boolean>(false)
  const [reCaptchaToken, setRecaptchaToken] = useState<string>('')
  const toggleVisibility = () => setIsVisible(!isVisible)
  const toggleConfirmVisibility = () => setIsConfirmVisible(!isConfirmVisible)
  const navigate = useNavigate()
  const { setActiveWorkspace } = useWorkspaceContextData()
  const handleRecaptchaVerify = useCallback((token) => {
    setRecaptchaToken(token)
  }, [])
  const signup = async (e: any) => {
    e.preventDefault();
    if (confirmPassword !== password) {
      setError('Please verify your password!');
      return;
    }
    try {
      const res = await api.post('/auth/signup', { token: reCaptchaToken, firstname, lastname, email, password, accountType });
      if (res.data?.accessToken && res.data?.refreshToken) {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        localStorage.setItem('email', res.data.email);
        localStorage.setItem('user', JSON.stringify(res.data));
        Mixpanel.login(res.data.uuid);
  
        // Track signup success
        Mixpanel.track("User signed up", {
          "firstname": firstname,
          "lastname": lastname,
          "email": email,
        });
  
        if (res.data.new) Mixpanel.signup(res.data);
        setActiveWorkspace?.(res.data.workspace ?? '');
        navigate('/');
      } else {
        setError(res.data.message ?? 'Registration was not successful. Try again.');
        setTimeout(() => setError(''), 3000);
      }
    } catch (err) {
      console.log('Signup failed', err);
      const error = err as AxiosError;
      if (error instanceof AxiosError) {
        const message = error.response?.data?.message || 'Registration was not successful.';
        setError(message);
  
        // Track signup failure
        Mixpanel.track("Signup failed", {
          "firstname": firstname,
          "lastname": lastname,
          "email": email,
          "error_message": message
        });
      } else {
        setError('Signup was not successful. ' + err?.message);
  
        // Track signup failure with generic error
        Mixpanel.track("Signup failed", {
          "firstname": firstname,
          "lastname": lastname,
          "email": email,
          "error_message": err?.message
        });
      }
      setTimeout(() => setError(''), 3000);
    }
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }
  const isFormValid =
    firstname.length > 0 &&
    lastname.length > 0 &&
    email.length > 0 &&
    password.length > 5 &&
    password === confirmPassword &&
    validateEmail(email)
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex h-full w-full flex-col items-center justify-center px-3">
        <div className="flex flex-col items-center pb-2">
          <Image key={'logo'} src="/img/strom-logo.png" alt="strom logo" width={140} radius="none" className="mb-6" />
          <p className="text-xl font-medium">Welcome</p>
          <p className="text-small text-default-500">Create your account to get started</p>
        </div>
        <div className="mt-2 flex w-full max-w-md flex-col gap-4 rounded-large bg-content1 px-8 py-6 shadow-small">
          <form className="flex flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
            <div className="flex flex-wrap items-center gap-2 sm:flex-nowrap">
              <Input
                isRequired
                onChange={(e) => setFirstName(e.target.value)}
                label="First name"
                name="firstname"
                placeholder="Your first name"
                type="text"
                variant="bordered"
              />
              <Input
                isRequired
                onChange={(e) => setLastName(e.target.value)}
                label="Last name"
                name="lastname"
                placeholder="Your last name"
                type="text"
                variant="bordered"
              />
            </div>
            <Input
              isRequired
              onChange={(e) => setEmail(e.target.value)}
              label="Email address"
              name="email"
              placeholder="Your email"
              type="email"
              variant="bordered"
            />
            <Input
              isRequired
              endContent={
                <button type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-closed-linear" />
                  ) : (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-bold" />
                  )}
                </button>
              }
              label="Password"
              name="password"
              isInvalid={confirmPassword.length > 0 && confirmPassword !== password}
              placeholder="Choose your password"
              onChange={(e) => setPassword(e.target.value)}
              type={isVisible ? 'text' : 'password'}
              variant="bordered"
            />
            <Input
              isRequired
              endContent={
                <button type="button" onClick={toggleConfirmVisibility}>
                  {isConfirmVisible ? (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-closed-linear" />
                  ) : (
                    <Icon className="pointer-events-none text-2xl text-default-400" icon="solar:eye-bold" />
                  )}
                </button>
              }
              label="Confirm password"
              name="confirmPassword"
              placeholder="Confirm your password"
              type={isConfirmVisible ? 'text' : 'password'}
              variant="bordered"
              isInvalid={confirmPassword.length > 0 && confirmPassword !== password}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Spacer y={0} />
            <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
            <Button color="secondary" type="submit" onClick={signup} isDisabled={reCaptchaToken === '' || !isFormValid}>
              Sign up
            </Button>
            {error && <p className={'text-danger text-center text-sm'}>{error}</p>}
            <p className="text-center text-small">
              By signing up, you agree to our&nbsp;
              <Link href="https://strom.ai/terms" size="sm" isExternal>
                Terms
              </Link>
              &nbsp;&&nbsp;
              <Link
                href="https://strom.ai/privacy"
                size="sm"
                isExternal
              >
                Privacy
              </Link>
            </p>
          </form>
          {/* <div className="flex items-center gap-4">
            <Divider className="flex-1" />
            <p className="shrink-0 text-tiny text-default-500">OR</p>
            <Divider className="flex-1" />
          </div>
          <div className="flex flex-col gap-2">
            <Button startContent={<Icon icon="logos:salesforce" width={24} />} variant="bordered">
              Continue with Salesforce (coming soon)
            </Button>
          </div> */}
          <p className="text-center text-small">
            Already have an account?&nbsp;
            <Link href="login" size="sm">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}
