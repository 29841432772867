import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import api from 'src/lib/api'
import sampleCode from 'src/data/sample/code.json'

import { Card, CardBody, Tabs, Tab, Image, Button, ButtonGroup } from '@nextui-org/react'
import { ThumbsUp, ThumbsDown } from 'lucide-react'

import { CodeEditor } from './code-editor'
import { CodePreview } from './code-preview'
import { useParams } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'

export const StromDeveloper = forwardRef(function StromDev(props, ref) {
  useImperativeHandle(ref, () => ({
    triggerGenerate: () => {
      console.log('triggerGenerate dev')
      generateCode()
    },
  }))

  const [initialized, isInitialized] = useState<boolean>(false)
  const [thinking, isThinking] = useState<boolean>(false)
  const [selected, setSelected] = useState('preview')
  const [preview, setPreview] = useState<string | undefined>()
  const [previewJS, setPreviewJS] = useState<string | undefined>()
  const [html, setHtml] = useState<string | undefined>()
  const [javascript, setJavascript] = useState<string | undefined>()
  const [apex, setApex] = useState<string | undefined>()
  const [css, setCss] = useState<string | undefined>()
  const [labels, setLabels] = useState<string | undefined>()
  const [compiledCode, setCompiledCode] = useState<string>('')

  const { client, project } = useParams()

  const showTab = (tab: string) => {
    Mixpanel.btn('ai', `code_tab_${tab}`, { client: client, project: project })
    setSelected(tab)
    if (tab === 'preview' && compiledCode) {
      eval(compiledCode)
    }
  }

  // Get code we already generated recently
  const getCode = async () => {
    console.log('getCode', project, client)
    return api
      .get(`/develop/${project}/get`)
      .then((res) => {
        console.log('getCode', res.data)
        if (res.data?.html != '') {
          setCodeBlocks(res.data)
          Mixpanel.act('ai', 'code_get_done', { client: client, project: project })
        }
      })
      .catch((err) => {
        console.error('getCode', err.message, err.response?.data)
        Mixpanel.err('ai', 'code_get_done', err.message, { client: client, project: project })
      })
      .finally(() => {
        // isThinking(false)
      })
  }

  // Get code freshly generated by the AI
  const generateCode = async () => {
    console.log('generateCode', project)
    isThinking(true)
    Mixpanel.act('ai', 'code_generate', { client: client, project: project })
    return api
      .post(`/develop/${project}/generate`)
      .then((res) => {
        console.log('generateCode', res.data)
        if (res.data?.html != '') {
          setCodeBlocks(res.data)
          generatePreview()
          Mixpanel.act('ai', 'code_generate_done', { client: client, project: project })
        } else {
          Mixpanel.err('ai', 'code_generate_done', 'empty code', { client: client, project: project })
        }
      })
      .catch((err) => {
        console.error('generateCode', err.message, err.response?.data)
        Mixpanel.err('ai', 'code_generate_done', err.message, { client: client, project: project })
      })
      .finally(() => {
        isThinking(false)
      })
  }

  // Get preview generated by the AI
  const generatePreview = async () => {
    console.log('generatePreview', project)
    isThinking(true)
    Mixpanel.act('ai', 'code_preview', { client: client, project: project })
    return api
      .post(`/develop/${project}/preview`)
      .then((res) => {
        console.log('generatePreview', res.data)
        setCodeBlocks(res.data)
        Mixpanel.act('ai', 'code_preview_done', { client: client, project: project })
      })
      .catch((err) => {
        console.error('generatePreview', err.message, err.response?.data)
        Mixpanel.err('ai', 'code_preview_done', err.message, { client: client, project: project })
      })
      .finally(() => {
        isThinking(false)
      })
  }

  // Fill the code editor
  const setCodeBlocks = async (code: any) => {
    console.log('setCodeBlocks', code)
    if (code.html) setHtml(code.html)
    if (code.javascript) setJavascript(code.javascript)
    if (code.apex) setApex(code.apex)
    if (code.css) setCss(code.css)
    if (code.labels) setLabels(code.labels)
    if (code.preview) setPreview(code.preview)
    if (code.preview_js) setPreviewJS(code.preview_js)
    isThinking(false)
  }

  // Use sample data if connection is broken
  const checkConnection = () => {
    return api
      .get(`/ping`)
      .then((res) => console.log('checkConnection success'))
      .catch((err) => {
        console.log('checkConnection error (use sample data)')
        setCodeBlocks(sampleCode)
        isInitialized(true)
      })
  }

  // Kick if off
  const initialize = async () => {
    console.log('AI Developer loading')
    checkConnection()
    await getCode()
    isInitialized(true) // wait till we have an api response to avoid flickering
    console.log('AI Developer loaded')
  }

  useEffect(() => {
    console.log('AI Developer is here')
    initialize() // in an extra function because async/await is not working in useEffect
  }, [project])

  useEffect(() => {
    if (preview && previewJS) {
      api
        .post('/code/compile', { html: preview, javascript: previewJS })
        .then((res) => {
          if (res.data?.code) {
            setCompiledCode(res.data.code)
          }
        })
        .catch((error) => {
          console.log('error generated', error)
        })
    }
  }, [preview, previewJS])

  useEffect(() => {
    if (selected === 'preview') eval(compiledCode)
  }, [selected, compiledCode])

  return (
    <>
      {initialized && thinking && (
        <div className="flex flex-grow place-content-center place-items-center">
          <Image alt="ai loading animation" height={40} radius="sm" src="/img/ai-loading-lottie.gif" width={400} />
        </div>
      )}
      {initialized && html && (
        <Card className="flex flex-grow" style={{ maxHeight: 'calc(100vh - 154px)' }}>
          <CardBody className="flex flex-grow">
            <div className="absolute right-[20px] top-[16px]">
              <ButtonGroup>
                <Button isIconOnly size="sm">
                  <ThumbsUp size={16} />
                </Button>
                <Button isIconOnly size="sm">
                  <ThumbsDown size={16} />
                </Button>
              </ButtonGroup>
            </div>
            <div className="flex flex-grow flex-col">
              <Tabs
                aria-label="Options"
                selectedKey={selected}
                onSelectionChange={showTab}
                radius="full"
                // className="justify-center"
              >
                <Tab key="preview" title="Preview" className="flex flex-grow">
                  {initialized && preview && (
                    <div className="flex flex-col flex-grow" id="sf-slds">
                      <div className="relative flex h-full">
                        {thinking ? <p>Generating preview …</p> : <CodePreview html={preview} css={css} />}
                      </div>
                    </div>
                  )}
                </Tab>
                {html && (
                  <Tab key="html" title="HTML" className="flex flex-grow">
                    <CodeEditor code={html} />
                  </Tab>
                )}
                {javascript && (
                  <Tab key="javascript" title="Javascript" className="flex flex-grow">
                    <CodeEditor code={javascript} />
                  </Tab>
                )}
                {apex && (
                  <Tab key="apex" title="APEX" className="flex flex-grow">
                    <CodeEditor code={apex} />
                  </Tab>
                )}
                {css && (
                  <Tab key="css" title="CSS" className="flex flex-grow">
                    <CodeEditor code={css} />
                  </Tab>
                )}
                {labels && (
                  <Tab key="labels" title="Labels" className="flex flex-grow">
                    <CodeEditor code={labels} />
                  </Tab>
                )}
              </Tabs>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  )
})
