'use client'

import React, { useEffect, useRef, useState } from 'react'
import { Button, Select, SelectedItems, SelectItem, Tooltip, Kbd } from '@nextui-org/react'
import { Icon } from '@iconify/react'

import { cn } from './cn'

import PromptInput from './prompt-input'
import api from 'src/lib/api'
import { useWorkspaceContextData } from 'src/context/workspace'

export default function Component({ onChange, onTextSubmit, onFileSubmit, onTargetOrgChange, defaultOrg }) {
  // const ideas = [
  //   {
  //     title: "Create a blog post about NextUI",
  //     description: "explain it in simple terms",
  //   },
  //   {
  //     title: "Give me 10 ideas for my next blog post",
  //     description: "include only the best ideas",
  //   },
  //   {
  //     title: "Compare NextUI with other UI libraries",
  //     description: "be as objective as possible",
  //   },
  //   {
  //     title: "Write a text message to my friend",
  //     description: "be polite and friendly",
  //   },
  // ];

  const [prompt, setPrompt] = React.useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const handleInputChange = (value: string) => {
    setPrompt(value)
    if (onChange) {
      onChange({ target: { value } })
    }
  }
  const handleFileSelect = (e) => {
    const file = e.target.files?.[0]
    if (file) {
      onFileSubmit(file)
    }
  }

  const [orgs, setOrgs] = useState([])
  const { activeWorkspace } = useWorkspaceContextData()
  const loadOrgs = async () => {
    api
      .get(`/orgs/${activeWorkspace}`)
      .then((response) => {
        const orgsData = response.data
        if (orgsData && orgsData.length > 0) {
          setOrgs(orgsData)
        }
      })
      .catch(console.error)
  }
  useEffect(() => {
    loadOrgs()
    console.log('loadings orgs')
  }, [activeWorkspace])
  return (
    <div className="flex w-full flex-col gap-4">
      {/* <ScrollShadow hideScrollBar className="flex flex-nowrap gap-2" orientation="horizontal">
        <div className="flex gap-2">
          {ideas.map(({title, description}, index) => (
            <Button key={index} className="flex h-14 flex-col items-start gap-0" variant="flat">
              <p>{title}</p>
              <p className="text-default-500">{description}</p>
            </Button>
          ))}
        </div>
      </ScrollShadow> */}
      <div className="flex grow justify-center pt-4">
        <Select
          items={orgs}
          radius="md"
          label="Target organization"
          placeholder="Select an SFDC organization"
          description="Select the target organization for your requirements."
          className="max-w-sm"
          size="sm"
          labelPlacement="inside"
          defaultSelectedKeys={[defaultOrg]}
          onChange={(e) => onTargetOrgChange(e.target.value)}
          renderValue={(items: SelectedItems<any>) => {
            return items.map((item) => (
              <div key={item.key} className="flex items-center gap-2">
                <div className="flex flex-col">
                  <span>{item.data.alias}</span>
                  {/* <span className="text-default-500 text-tiny">({item.data.username})</span> */}
                </div>
              </div>
            ))
          }}
        >
          {(user) => (
            <SelectItem key={user.alias} textValue={user.alias}>
              <div className="flex gap-2 items-center">
                <div className="flex flex-col">
                  <span className="text-small">{user.alias}</span>
                  <div className="flex text-tiny text-default-500 items-center">
                    <Icon icon="solar:user-circle-bold" width={14} />
                    &nbsp;{user.username}
                  </div>
                </div>
              </div>
            </SelectItem>
          )}
        </Select>
      </div>
      <form className="flex w-full flex-col items-start rounded-medium bg-default-100 transition-colors hover:bg-default-200/70">
        <PromptInput
          classNames={{
            inputWrapper: '!bg-transparent shadow-none',
            innerWrapper: 'relative',
            input: 'pt-1 pl-2 pb-6 !pr-10 text-medium',
          }}
          endContent={
            <div className="flex items-end gap-2">
              <Tooltip content={<Kbd keys={['command', 'enter']} />}>
                <Button
                  color={!prompt ? 'default' : 'secondary'}
                  isDisabled={!prompt}
                  radius="md"
                  size="sm"
                  variant="solid"
                  onClick={onTextSubmit}
                  startContent={
                    <Icon
                      className={cn(
                        '[&>path]:stroke-[2px]',
                        !prompt ? 'text-default-600' : 'text-secondary-foreground',
                      )}
                      icon="solar:arrow-up-linear"
                      width={20}
                    />
                  }
                >
                  Send requirements
                </Button>
              </Tooltip>
            </div>
          }
          minRows={3}
          radius="lg"
          value={prompt}
          variant="flat"
          onValueChange={handleInputChange}
          onSubmit={onTextSubmit}
        />
        <div className="flex w-full items-center justify-between gap-2 overflow-scroll px-4 pb-1">
          <div className="flex w-full gap-1 md:gap-3">
            <Button
              size="sm"
              startContent={<Icon className="text-default-500" icon="solar:paperclip-linear" width={18} />}
              variant="flat"
              onClick={() => fileInputRef.current?.click()}
            >
              Upload recording (e.g. meeting, voice memo, etc.)
            </Button>
            <input onChange={handleFileSelect} type="file" id="file" ref={fileInputRef} style={{ display: 'none' }} />
            {/* <Button
              size="sm"
              startContent={
                <Icon className="text-default-500" icon="solar:notes-linear" width={18} />
              }
              variant="flat"
            >
              Templates
            </Button> */}
          </div>
          {/* <p className="py-1 text-tiny text-default-400">{prompt.length}/2000</p> */}
        </div>
      </form>
    </div>
  )
}
