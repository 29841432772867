import React, { createContext, useContext, useEffect, useState } from 'react'
import IO, { Socket } from 'socket.io-client'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

type SocketContextType = {
  io: Socket
  setIO?: (value: Socket) => void
  isSocketConnected: boolean
}

export const SocketContext = createContext<SocketContextType>({ io: null })

export const SocketProvider = ({ children }: Props) => {
  const [io, setIO] = useState<Socket>(null)
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false)
  useEffect(() => {
    // Create a new Socket.IO client instance with the token
    const socket = IO(import.meta.env.VITE_API_URL || 'http://localhost:4001/')
    // Connect the Socket.IO client once the token is loaded
    socket.connect()

    // Set the connected client instance in the state
    setIO(socket)

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect()
    }
  }, [])

  const onIOConnect = async () => {
    setIsSocketConnected(true)
    window.socket = io
    console.log('======socket is connected')
  }

  const onIoDisconnect = (reason: string) => {
    window.socket = null
    console.log('======socket is disconnected:', reason)
    setIsSocketConnected(false)
  }

  const onIOConnectError = (err: any) => {
    console.log('======socket connection error', err)
  }
  useEffect(() => {
    if (io) {
      console.log('======socket provider')
      io.on('connect', onIOConnect)
      io.on('disconnect', onIoDisconnect)
      io.on('connecct_error', onIOConnectError)
      return () => {
        io.off('connect', onIOConnect)
        io.off('disconnect', onIoDisconnect)
        io.off('connecct_error', onIOConnectError)
      }
    }
  }, [io])

  return (
    <SocketContext.Provider
      value={{
        io,
        setIO,
        isSocketConnected,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}
export const useSocketContextData = () => useContext(SocketContext)
