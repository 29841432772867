import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Card, CardHeader, CardBody, CardProps, Image, Link, Divider, Button, CardFooter, ScrollShadow } from '@nextui-org/react'
import Mixpanel from 'src/lib/mixpanel'

import { useWorkspaceContextData } from 'src/context/workspace'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { Icon } from '@iconify/react';

import ActionCard from './action-card'
import api from 'src/lib/api'

export default function Home(props: CardProps) {
  const { t } = useTranslation()

  const [initialized, setInitialized] = useState<boolean>(false)
  const [urs, setUrs] = useState<any>([])
  const [opener, setOpener] = useState<Window | null>(null)
  const { activeWorkspace } = useWorkspaceContextData()

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const loadInstances = () => {
    api
      .get(`/urs/list/${activeWorkspace}`)
      .then((res) => {
        setUrs((res.data ?? []).slice(0, 20)) //retrieve 20 latest items
      })
      .catch((err) => {
        console.log('Clients error', err.message)
        // setClients(testClients)
      })
      .finally(() => setInitialized(true))
  }

  useEffect(() => {
    loadInstances()
  }, [activeWorkspace])

  useEffect(() => {
    Mixpanel.scr('home')
  }, [])

  const cardRef = React.useRef<HTMLDivElement>(null)
  const [user, setUser] = useState<any>(null)

  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex flex-col w-full min-h-screen p-3 overflow-y-auto">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Hi {user?.firstname ? `${user?.firstname}` : 'there'}</h1>
              <p className="text-small text-default-400 lg:text-medium">
                Access your dashboard, recent activities, and quick links.
              </p>
            </div>
          </header>
        </div>

        <div className="flex flex-col gap-10">

          {/* SECTION: Get started */}
          <div className="flex px-2 2xl:px-8 max-w-screen-xl">
            <div className="flex flex-col w-full lg:w-3/4">
              <div className="flex items-center gap-1"><p className="text-2xl text-default-600 font-small pb-4">Get started</p></div>
              <div className="flex flex-col lg:flex-row gap-5">
                <div className="flex w-full lg:w-1/2">
                  <div className="w-full">
                    <div className="relative" style={{ paddingTop: '56.25%' }}>
                      <a href="https://www.youtube.com/watch?v=33Fjkj47K1U" target="_blank" rel="noopener noreferrer">
                        <img
                          className="absolute top-0 left-0 w-full h-full object-cover cursor-pointer"
                          alt="NextUI hero Image"
                          src="/img/quick-start-guide-video-thumbnail.png"
                        />
                      </a>
                      {/* <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/OrKsPtI7RX4?si=ibtBqAszLZkDrOjy"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full lg:w-1/2 gap-3 place-content-evenly">
                  <ActionCard
                    icon="ph:number-one-bold"
                    title="Create new project"
                    description="Start by creating a new project to begin crafting documents."
                    href="/urs"
                  />
                  <ActionCard
                    icon="ph:number-two-bold"
                    title="Onboarding by CEO"
                    description="Book a free session with our CEO to fast-track your success."
                    href="https://calendly.com/strom_ai/onboarding-w-marvin"
                    isExternal
                    chip="Free"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full lg:w-1/4 hidden">
              <p className="text-2xl">Get support</p>
              <p className="text-small text-default-400 lg:text-medium">Access our resources.</p>
              <div className="flex flex-col gap-3 pt-5">
                <Link href="https://gavel.notion.site/Help-center-ebf7f824f0044288b734c512cbb1f129" color="foreground" isExternal>
                  <Icon icon="solar:question-circle-outline" className="text-gray-300 mr-2 text-xl" />
                  <span>Help Center</span>
                </Link>
                <Link href="https://gavel.notion.site/Tutorials-6656ca35dbce47bf86fb7bb62a36c3cc" color="foreground" isExternal>
                  <Icon icon="solar:lightbulb-outline" className="text-gray-300 mr-2 text-xl" />
                  <span>Tutorials</span>
                </Link>
                {/* <Link href="https://youtu.be" color="foreground" isExternal>
                  <Icon icon="solar:video-library-outline" className="text-gray-300 mr-2 text-xl" />
                  <span>Demo Video</span>
                </Link> */}
                <Link href="mailto:support@strom.ai?subject=I need support" color="foreground" isExternal>
                  <Icon icon="solar:chat-line-outline" className="text-gray-300 mr-2 text-xl" />
                  <span>Support</span>
                </Link>
                <Link href="https://strom.ai/slack" color="foreground" isExternal>
                  <Icon icon="mdi:slack" className="text-gray-300 mr-2 text-xl" />
                  <span>Join Slack Community</span>
                </Link>
              </div>
            </div>
          </div>

          {/* SECTION: Latest projects */}
          <div className="flex flex-col px-2 2xl:px-8">
            <div className="flex items-center gap-1"><p className="text-2xl text-default-600 font-small pb-4">Recently worked on</p></div>
            <div className="overflow-x-auto relative max-w-full">
              <ScrollShadow orientation="horizontal" size={50} hideScrollBar className="flex w-full max-w-full snap-x justify-start">
                <div className="grid grid-flow-col auto-cols-max gap-4 w-0">
                  {urs.map((document, index) => (
                  // {[...Array(10)].map((_, index) => ( // demo
                    <Card isPressable onPress={() => navigate(`/urs/${document.uuid}`)} className="py-1 snap-start">
                      <CardHeader className="pt-2 pb-1 flex-col items-start">
                        <Icon icon="lucide:file-text" width="18" />
                      </CardHeader>
                      <CardBody className="overflow-visible pt-1 pb-2 w-[200px]">
                        <h4 className="font-medium text-medium line-clamp-2 leading-5">{document.title?.length > 0 ? document.title : 'Untitled document'}</h4>
                      </CardBody>
                      <CardFooter className="text-default-500 py-2">
                        <Icon icon="lucide:history" />
                        <small className="ml-1 truncate">{dayjs(document.updatedAt).fromNow()}</small>
                      </CardFooter>
                    </Card>
                  ))}
                </div>
              </ScrollShadow>
            </div>
          </div>

          {/* SECTION: Explore more */}
          <div className="flex flex-col w-full pb-6 px-2 2xl:px-8 max-w-screen-xl">
            <div className="flex items-center gap-1"><p className="text-2xl text-default-600 font-small pb-4">Explore more</p></div>
            <div className="flex flex-col lg:flex-row gap-3 w-full md:w-3/4">
              <Card ref={cardRef} className="group relative w-full bg-neutral-900 shadow-large" radius="lg">
                <CardBody className="">
                  <div className="flex flex-col">
                    <p className="text-xl text-neutral-50">Invite a member</p>
                    <p className="text-small text-neutral-400">
                      Add new members to your team for better collaboration.
                    </p>
                  </div>
                </CardBody>
                <CardFooter>
                  <Link href="/team" color="foreground">
                    <Button color="secondary">Invite a member</Button>
                  </Link>
                </CardFooter>
              </Card>
              <Card ref={cardRef} className="group relative w-full bg-neutral-900 shadow-large" radius="lg">
                <CardBody className="">
                  <div className="flex flex-col">
                    <p className="text-xl text-neutral-50">Join Slack community</p>
                    <p className="text-small text-neutral-400">
                      Connect with peers and share insights on Slack.
                    </p>
                  </div>
                </CardBody>
                <CardFooter>
                  <Link href="https://strom.ai/slack" color="foreground" isExternal>
                    <Button color="secondary">Join Slack community</Button>
                  </Link>
                </CardFooter>
              </Card>
              <Card ref={cardRef} className="group relative w-full bg-neutral-900 shadow-large" radius="lg">
                <CardBody className="">
                  <div className="flex flex-col">
                    <p className="text-xl text-neutral-50">Define settings</p>
                    <p className="text-small text-neutral-400">
                      Set up global preferences that apply to your company.
                    </p>
                  </div>
                </CardBody>
                <CardFooter>
                  <Link href="/settings" color="foreground">
                    <Button color="secondary">Define settings</Button>
                  </Link>
                </CardFooter>
              </Card>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
