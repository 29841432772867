import api, { baseURL } from 'src/lib/api'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Image, Button, Card, CardBody, ScrollShadow, useDisclosure, Spinner } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'
import { useSocketContextData } from 'src/context/socket'
import { useWorkspaceContextData } from 'src/context/workspace'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import CreateUrdWindow from 'src/pages/ursList/CreateUrdWindow'
import toast from 'react-hot-toast'
dayjs.extend(relativeTime)

function UrsDocument({
  urs,
  removeDocument,
  index,
}: {
  index: number
  urs?: any
  removeDocument: (uuid: string) => void
}) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const gradients = [
    'bg-gradient-to-br from-[#FFB457] to-[#FF705B]',
    'bg-gradient-to-br from-[#34D399] to-[#3B82F6]',
    'bg-gradient-to-br from-[#FBBF24] to-[#F59E0B]',
    'bg-gradient-to-br from-[#10B981] to-[#065F46]',
    'bg-gradient-to-br from-[#A78BFA] to-[#7C3AED]',
    'bg-gradient-to-br from-[#F472B6] to-[#DB2777]',
    'bg-gradient-to-br from-[#60A5FA] to-[#2563EB]',
    'bg-gradient-to-br from-[#EC4899] to-[#BE185D]',
    'bg-gradient-to-br from-[#F87171] to-[#B91C1C]',
    'bg-gradient-to-br from-[#C084FC] to-[#9333EA]',
    'bg-gradient-to-br from-[#F97316] to-[#EA580C]',
    'bg-gradient-to-br from-[#2DD4BF] to-[#0891B2]',
    'bg-gradient-to-br from-[#4ADE80] to-[#16A34A]',
    'bg-gradient-to-br from-[#818CF8] to-[#6366F1]',
    'bg-gradient-to-br from-[#FDE68A] to-[#F59E0B]',
    'bg-gradient-to-br from-[#F9A8D4] to-[#E11D48]',
    'bg-gradient-to-br from-[#A3E635] to-[#65A30D]',
    'bg-gradient-to-br from-[#38BDF8] to-[#0284C7]',
    'bg-gradient-to-br from-[#FDBA74] to-[#FB923C]',
    'bg-gradient-to-br from-[#FB7185] to-[#E11D48]',
  ]
  const { activeWorkspace } = useWorkspaceContextData()

  return (
    <>
      <Card isPressable shadow="none" onPress={() => navigate(`/urs/${urs.uuid}`)} className="h-full">
        <div className="overflow-visible p-0">
          <Image
            radius="lg"
            width="100%"
            alt={urs.title?.length > 0 ? urs.title : 'Untitled document'}
            className="z-0 w-full object-cover p-6 border-1"
            src="/img/urs-icon.png"
          />
        </div>
        <div className="flex-col w-full p-3 space-y-3 border-0">
          <div className="flex flex-col w-full text-left gap-1">
            <h4 className="font-medium text-small truncate">
              {urs.title?.length > 0 ? urs.title : 'Untitled document'}
            </h4>
            <p className="text-tiny truncate text-default-400">Edited {dayjs(urs.updatedAt).fromNow()}</p>
          </div>
          <div className="flex justify-end w-full gap-2">
            <Button
              onClick={() => {
                const popup = window.open(`${baseURL}/urs/export/${activeWorkspace}/${urs.uuid}`)
                if (popup) {
                  popup?.focus()
                  popup.addEventListener('unload', () => {
                    popup.close()
                  })
                }
              }}
              size="sm"
              isIconOnly
              radius="full"
              color="secondary"
              variant="bordered"
            >
              <Icon icon="solar:download-minimalistic-linear" width="18" />
            </Button>
            <Button
              onClick={async () => {
                setLoading(true)
                await removeDocument(urs.uuid)
                setLoading(false)
              }}
              size="sm"
              isIconOnly
              radius="full"
              color="danger"
              variant="bordered"
            >
              <Icon icon="solar:trash-bin-trash-linear" width="18" />
            </Button>
          </div>
        </div>
      </Card>
    </>
  )
}

export default function UrsList() {
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [urs, setUrs] = useState<any>([])
  const [opener, setOpener] = useState<Window | null>(null)
  const { activeWorkspace } = useWorkspaceContextData()
  const [user, setUser] = useState(null)
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  const { io } = useSocketContextData()
  // Create new client modal and listen to "new" in the URL
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const loadInstances = () => {
    api
      .get(`/urs/list/${activeWorkspace}`)
      .then((res) => {
        setUrs(res.data ?? [])
      })
      .catch((err) => {
        console.log('Clients error', err.message)
        // setClients(testClients)
      })
      .finally(() => setInitialized(true))
  }

  const handleOpen = () => {
    onOpen()
  }

  useEffect(() => {
    loadInstances()
  }, [activeWorkspace])

  useEffect(() => {}, [io])

  useEffect(() => {
    if (window.location.pathname === '/clients/new') {
      handleOpen()
    }
  }, [])
  const fileInputRef = useRef<HTMLInputElement | null>()
  const [prompt, setPrompt] = useState<string>('')
  const [creating, setCreating] = useState<boolean>(false)

  const createNewUrs = async () => {
    /*setCreating(true)
    const res = await io.emitWithAck('createUrs', {
      workspaceUUID: activeWorkspace,
      token: localStorage.getItem('accessToken'),
    })
    navigate(`/urs/${res.uuid}`)
    setCreating(false)*/
    
    // Mixpanel.track("Document creation intent", {
    //   "document_type": "proposal", // e.g., "proposal", "quote", "URS"
    //   "user_id": "12345",
    //   "project_id": "67890",
    //   "action_step": "clicked_create" // Optional: indicates action step
    // });

    Mixpanel.track("Document creation intent", { "screen": "projects" });

    onOpen()
  }
  const removeDocument = async (uuid: string) => {
    const res = await api.delete(`/urs/${uuid}`)
    setUrs(urs.filter((item) => item.uuid !== uuid))
    return res.data
  }
  const [sendingEmail, setSendingEmail] = useState<boolean>(false)
  const resendVerification = async () => {
    if (!sendingEmail) {
      setSendingEmail(true)
      const res = await api.post('/auth/resend')
      setSendingEmail(false)
      toast.success('Verification email has been sent successfully')
    }
  }
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <CreateUrdWindow isOpen={isOpen} onClose={onClose} />

      <div className="flex flex-col w-full h-screen p-3 max-w-[1000px]">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Projects</h1>
              <p className="text-small text-default-700 lg:text-medium">
                View, organize, and track progress across all your projects.
              </p>
            </div>
          </header>
        </div>

        {!user?.isActive && (
          <div className={'flex w-full p-2 mb-3 rounded-xl bg-warning-100'}>
            <p className={'text-sm flex flex-row gap-1 flex-1 '}>
              {'⚠️ You need to verify your account in order to use this feature.'}
              <p className={'cursor-pointer underline'} onClick={resendVerification}>
                Click here to resend verification email.
              </p>
            </p>
            {sendingEmail && <Spinner size={'sm'} />}
          </div>
        )}
        {!Array.isArray(urs) || urs.length == 0 ? (
          <div className="flex flex-col items-center place-content-center grow gap-3 px-3">
            {/* <Image
              src="/img/empty-state-chat.png"
              alt="no projects available"
              width={400}
              radius="none"
              className="mb-8"
            /> */}
            <Icon icon="lucide:file-x-2" width="80" className="text-default-600" />
            <div className="flex flex-col">
              <p className="text-2xl text-center font-semibold">No documents available</p>
              <p className="text-base text-center">Get started by creating your first document</p>
            </div>
            <Button
              onClick={() => {
                createNewUrs()
              }}
              color="secondary"
              size="lg"
              radius="md"
              isDisabled={creating || !user?.isActive}
              startContent={
                creating ? (
                  <Spinner size={'md'} />
                ) : (
                  <Icon className="flex-none" icon="solar:add-circle-linear" width={20} />
                )
              }
            >
              Create first document
            </Button>
          </div>
        ) : (
          <div className="flex gap-13 w-full max-w-full max-h-full px-2 2xl:px-8 overflow-y-auto">
            <ScrollShadow className="space-y-3" hideScrollBar size={100}>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 grow max-h-full max-w-full">
                <Card shadow="none" isPressable onPress={() => createNewUrs()} className="h-full">
                  <CardBody
                    className="overflow-visible p-0 justify-center items-center border-1"
                    style={{ borderRadius: '14px' }}
                  >
                    <Icon icon="mingcute:add-line" width="80" style={{ color: '#38A1B1' }} />
                  </CardBody>
                  <div className="flex-col w-full p-3 space-y-3">
                    <div className="flex flex-col w-full text-left gap-1">
                      <h4 className="font-medium text-small truncate">New project</h4>
                      <p className="text-tiny text-white/60">&nbsp;</p>
                    </div>
                    <div className="flex justify-end w-full gap-2" style={{ visibility: 'hidden' }}>
                      <Button size="sm" isIconOnly radius="full" color="secondary" variant="bordered">
                        <Icon icon="solar:document-add-linear" width="18" />
                      </Button>
                    </div>
                  </div>
                </Card>
                {urs.map((document, index) => (
                  <>
                    <UrsDocument
                      index={index}
                      key={`document_${document.id}`}
                      urs={document}
                      removeDocument={removeDocument}
                    />
                  </>
                ))}
              </div>
            </ScrollShadow>
          </div>
        )}
      </div>
    </>
  )
}
