'use client'

import type { TextAreaProps } from '@nextui-org/react'

import React from 'react'
import { Textarea } from '@nextui-org/react'

import { cn } from './cn'

const PromptInput = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ onSubmit, classNames = {}, ...props }, ref) => {
    return (
      <Textarea
        ref={ref}
        aria-label="Prompt"
        className="min-h-[40px]"
        classNames={{
          ...classNames,
          label: cn('hidden', classNames?.label),
          input: cn('py-0', classNames?.input, 'text-[14px] text-black'),
        }}
        minRows={1}
        placeholder="e.g. my client also needs a Salesforce integration that ..."
        radius="lg"
        variant="bordered"
        {...props}
        onKeyDown={(event) => {
          if ((event.ctrlKey || event.shiftKey) && event.key === 'Enter') {
            event.preventDefault() // Prevent the default Enter key behavior
            const textarea = ref?.current
            if (textarea) {
              const { selectionStart, selectionEnd, value } = textarea
              const newValue = value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd)
              textarea.value = newValue
              textarea.selectionStart = textarea.selectionEnd = selectionStart + 1 // Move the cursor to the new line
            }
          } else if (event.key === 'Enter') {
            event.preventDefault() // Prevent the default Enter key behavior
            if (onSubmit) {
              //@ts-ignore
              onSubmit()
            }
          }
        }}
      />
    )
  },
)

export default PromptInput

PromptInput.displayName = 'PromptInput'
