import type {Frequency, Tier} from "./pricing-types";

import {FrequencyEnum, TiersEnum} from "./pricing-types";

export const frequencies: Array<Frequency> = [
  {key: FrequencyEnum.Yearly, label: "Pay Yearly", priceSuffix: "per year"},
  {key: FrequencyEnum.Monthly, label: "Pay Monthly", priceSuffix: "per month"},
];

export const tiers: Array<Tier> = [
  {
    key: TiersEnum.Free,
    title: "Free",
    price: "Free",
    href: "#",
    featured: false,
    mostPopular: false,
    description: "For starters and hobbyists that want to try out.",
    features: [
      "1 editor included",
      "Unlimited drafts",
      "Basic templates",
      "Help center access"
    ],
    buttonText: "Continue with Free",
    buttonColor: "default",
    buttonVariant: "flat",
  },
  {
    key: TiersEnum.Professional,
    title: "Pro",
    description: "For small teams that have less that 10 members.",
    href: "#",
    mostPopular: false,
    price: {
      yearly: "$79",
      monthly: "$99",
    },
    featured: false,
    features: [
      "1 editor included",
      "Export to Salesforce",
      "Premium templates",
      "Email support",
    ],
    buttonText: "Get started",
    buttonColor: "default",
    buttonVariant: "flat",
  },
  {
    key: TiersEnum.Business,
    title: "Business",
    href: "#",
    featured: true,
    mostPopular: true,
    description: "For large teams that have more than 10 members.",
    price: {
      yearly: "$99",
      monthly: "$124",
    },
    priceSuffix: "per editor",
    features: [
      "Unlimited editors",
      "Export to Salesforce",
      "Premium templates",
      "Priority email support",
    ],
    buttonText: "Get started",
    buttonColor: "secondary",
    buttonVariant: "solid",
  },
  {
    key: TiersEnum.Enterprise,
    title: "Enterprise",
    price: "Individual",
    href: "#",
    featured: false,
    mostPopular: false,
    description: "For large teams that have more than 10 members.",
    features: [
      "Unlimited editors",
      "Export to Salesforce",
      "Premium templates",
      "Priority email support",
    ],
    buttonText: "Contact us",
    buttonColor: "default",
    buttonVariant: "flat",
  },
];
