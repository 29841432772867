import React, { Fragment, useEffect, useRef, useState } from 'react'
import MarkdownIt from 'markdown-it'
const md = new MarkdownIt()
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { ScrollShadow, Spinner, Button } from '@nextui-org/react'

import { ChevronLeftIcon, ChevronRightIcon, XIcon } from 'lucide-react'
// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined') {
  if (window)
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject
      const promise = new Promise((res, rej) => {
        resolve = res
        reject = rej
      })
      return { promise, resolve, reject }
    }
}
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

export default function DocumentPreview({ url: fileUrl, close }) {
  const [pdfUrl, setPdfUrl] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const [numPages, setNumPages] = useState<number>()
  const [currentPage, setCurrentPage] = useState(1)

  const ref = useRef<HTMLDivElement>()

  const fetchPdf = async () => {
    const response = await fetch(fileUrl)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    setPdfUrl(url)
    console.log(url)
  }
  function onDocumentLoadSuccess(props): void {
    setNumPages(props._pdfInfo.numPages)
  }

  useEffect(() => {
    fetchPdf()
  }, [fileUrl])
  useEffect(() => {
    if (currentPage > 0) {
      const element = document.getElementById(`page_${currentPage}`)
      if (element) {
        // element.scrollIntoView() // scroll thumbnail to top of window
      }
    }
  }, [currentPage])
  useEffect(() => {
    if (ref.current) {
      ref.current.focus() // Focus the container when the component mounts
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        close()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [close])
  return (
    <div
      className="markdown-paginator w-full h-full flex flex-col justify-center items-center"
      ref={ref}
      tabIndex={0}
    >
      {pdfUrl ? (
        <Fragment>
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <div className={'flex gap-6 h-[calc(100vh-0px)] p-4'}>
              <div className="relative h-full overflow-hidden border border-1 border-default-500 rounded-md p-3">
                <ScrollShadow
                  className="flex h-full flex-col gap-3"
                  hideScrollBar
                  size={100}
                >
                  <div className="flex flex-col gap-3">
                    {Array.from(new Array(numPages), (_el, index) => (
                      <div
                        className={`cursor-pointer`}
                        key={`page_${index + 1}`}
                        id={`page_${index + 1}`}
                      >
                        <Page 
                          pageNumber={index + 1} 
                          height={120} 
                          onClick={() => setCurrentPage(index + 1)} 
                          className={`rounded-md overflow-hidden ${
                            currentPage === index + 1 ? 'opacity-100' : 'opacity-60'
                          }`}
                        />
                        <p className="text-default-500 text-center text-tiny pt-1">
                          {`Page ${index + 1}`}
                        </p>
                      </div>
                    ))}
                  </div>
                </ScrollShadow>
              </div>

              <div className={'flex flex-1 justify-center items-center w-full relative h-full'}>
                <Page pageNumber={currentPage} className="flex max-h-full max-w-full object-contain h-full rounded-md overflow-hidden" renderMode="canvas" width={undefined} height={undefined} />
                <div className={'z-10 flex gap-2 absolute bottom-3 items-center rounded-xl bg-black/20 backdrop-blur-md px-2 py-1'}>
                  <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} isIconOnly variant="light">
                    <ChevronLeftIcon />
                  </Button>
                  <span className={'text-white text-md'}>
                    Page {currentPage} of {numPages}
                  </span>
                  <Button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, numPages ?? 0))} isIconOnly variant="light">
                    <ChevronRightIcon />
                  </Button>
                </div>
              </div>
            </div>
          </Document>
        </Fragment>
      ) : (
        <div>
          <div className={'fixed w-full h-full top-0 left-0'}>
            <div className={'bg-black/75 backdrop-blur-sm w-full h-full justify-center items-center flex gap-3 text-white'}>
              <Spinner size={'lg'} color="secondary" />
              <span className={'text-white text-xl'}>Generating preview…</span>
            </div>
          </div>
        </div>
      )}

      <button className={'absolute top-10 right-10 text-white'} onClick={() => close()}>
        <XIcon />
      </button>
    </div>
  )
}
