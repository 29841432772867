'use client'

import React, { forwardRef, useImperativeHandle, useState, Ref, ChangeEvent } from 'react'
import PromptInput from './prompt-input'

// Define the type for the props
interface PromptInputWithBottomActionsProps {
  onChange?: (event: ChangeEvent<{ value: string }>) => void
  inputRef?: Ref<HTMLInputElement>
  onSubmit?: () => void
  variant: 'dark' | 'light'
}

// Define the type for the ref methods
export interface PromptInputWithBottomActionsRef {
  resetPrompt: () => void
}

// ForwardRef function with typing
// eslint-disable-next-line react/display-name
const PromptInputWithBottomActions = forwardRef<PromptInputWithBottomActionsRef, PromptInputWithBottomActionsProps>(
  ({ onSubmit, onChange, inputRef, variant }, ref) => {
    const [prompt, setPrompt] = useState<string>('')

    const handleInputChange = (value: string) => {
      setPrompt(value)
      if (onChange) {
        onChange({ target: { value } } as ChangeEvent<{ value: string }>)
      }
    }

    // Use useImperativeHandle to expose the resetPrompt method
    useImperativeHandle(ref, () => ({
      resetPrompt: () => {
        setPrompt('')
      },
    }))

    return (
      <div className="flex w-full flex-col gap-4">
        <form
          className="flex w-full flex-col items-start rounded-medium bg-default-100 transition-colors hover:bg-default-200/70">
          <PromptInput
            classNames={{
              inputWrapper: '!bg-transparent shadow-none border-secondary',
              innerWrapper: 'relative border-secondary',
              input: 'pt-1 pl-2 pb-6 !pr-10 text-medium',
            }}
            minRows={3}
            radius="lg"
            ref={inputRef}
            value={prompt}
            variant={variant === 'dark' ? 'flat' : 'bordered'}
            className={variant === 'dark' ? '' : 'bg-white border-secondary'}
            onValueChange={handleInputChange}
            onSubmit={onSubmit}
            {...(variant === 'dark' ? {} : {color: 'secondary'})}
          />
        </form>
      </div>
    );
  },
)

export default PromptInputWithBottomActions
