'use client'

import type { TextAreaProps } from '@nextui-org/react'

import React from 'react'
import { Textarea } from '@nextui-org/react'

import { cn } from './cn'

const PromptInput = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ classNames = {}, ...props }, ref) => {
  return (
    <Textarea
      ref={ref}
      aria-label="Prompt"
      className="min-h-[200px]"
      classNames={{
        ...classNames,
        label: cn('hidden', classNames?.label),
        input: cn('py-0', classNames?.input),
      }}
      minRows={10}
      placeholder="Enter your project details here ..."
      radius="lg"
      variant="bordered"
      {...props}
    />
  )
})

export default PromptInput

PromptInput.displayName = 'PromptInput'
