import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Button, Image, Chip, Input, Textarea, ScrollShadow } from '@nextui-org/react'
import api from 'src/lib/api'
import Markdown from 'react-markdown'

export default function SupervisorMeetings() {
  const { t } = useTranslation()
  const [meetings, setMeetings] = useState<IMeetingList[]>([])
  const [password, setPassword] = useState<string>('')
  const [adminLock, setAdminLock] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [meeting, setMeeting] = useState<IMeeting | null>(null)
  const [loggingIn, setLoggingIn] = useState<boolean>(false)
  const loadMeetings = async () => {
    setLoggingIn(true)
    api
      .post('/meetings/supervisor/list', { password, page: currentPage ?? 0 })
      .then((res) => {
        setLoggingIn(false)
        if (res.data && typeof [] == typeof res.data) {
          setMeetings(res.data)
          setAdminLock(false)
        }
      })
      .catch((err) => {
        setLoggingIn(false)
        console.log(err)
      })
  }
  const openMeeting = async (meetingUUID: string) => {
    api
      .post(`/meetings/supervisor/info`, { password, meetingUUID })
      .then((res) => {
        setMeeting(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex flex-grow justify-center">
        {!password || adminLock ? (
          <div className="flex flex-col items-center place-content-center">
            <Image
              src="/img/empty-state-projects.png"
              alt="no projects available"
              width={400}
              radius="none"
              className="mb-8"
            />
            <Input
              placeholder={'Enter admin password to proceed'}
              className={'mb-8'}
              type={'password'}
              onChange={(e) => setPassword(e.target.value.toString())}
            />
            <Button color={'secondary'} onPress={loadMeetings} style={{ color: '#fff' }}>
              {'Unlock administrator area'}
            </Button>
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-4 p-8 min-w-[1200px] max-w-[1200px]">
            <div className="flex flex-col">
              <p className={`text-3xl font-semibold mb-3`}>{''}</p>
              <div className="flex flex-col flex-grow gap-4 border-1 rounded-lg border-stone-800 p-4">
                {meetings.map((meeting) => (
                  <Card
                    className="bg-stone-800"
                    isPressable
                    onPress={() => openMeeting(meeting.meetingUUID)}
                    key={meeting.meetingUUID}
                    radius="sm"
                  >
                    <CardBody className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
                      <div className="flex flex-col col-span-6 md:col-span-8 justify-between">
                        {meeting.user && (
                          <p className="text-xl">
                            {meeting.user?.email}
                          </p>
                        )}
                        <p className="text-m">{meeting.meetingUUID}</p>
                        <Chip className="mt-1" color="secondary" size="sm" variant="flat">
                          Created at:&nbsp;
                          {new Date(meeting.createdAt).toLocaleString('de-DE', {
                            month: '2-digit',
                            day: '2-digit',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </Chip>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
            </div>
            <div className="flex flex-col">
              <p className={`text-3xl font-semibold mb-3`}>{''}</p>
              <div className="flex flex-col flex-grow gap-4 border-1 rounded-lg border-stone-800 p-4">
                {meeting?.transcription?.conversation
                  ?.filter((item) => item.text)
                  ?.map((transcription, index) =>
                    ['you', 'speaker'].indexOf(transcription.source) > -1 ? (
                      <p
                        key={`transcription_${index}`}
                        style={{ textAlign: transcription.source === 'you' ? 'left' : 'right' }}
                        className={'text-m'}
                      >
                        {transcription.source} : {transcription.text}
                      </p>
                    ) : (
                      <Textarea
                        key={`transcription_${index}`}
                        isReadOnly
                        variant="bordered"
                        labelPlacement="inside"
                        defaultValue={transcription.text}
                        className="py-1"
                        style={{ textAlign: 'center' }}
                        color={'success'}
                      />
                    ),
                  )}
              </div>
            </div>
            <div className="flex flex-col">
              <p className={`text-3xl font-semibold mb-3`}>{''}</p>
              <div className="flex flex-col flex-grow gap-4 border-1 rounded-lg border-stone-800 p-4">
                <Card className="grow">
                  <ScrollShadow hideScrollBar size={100} id="summary">
                    <Markdown
                      children={meeting?.summary || ''}
                      className={`reactMarkDown ${
                        meeting?.summary && meeting?.summary.length > 0 ? 'visible' : 'hidden'
                      } border-2 rounded-xl border-secondary p-3 `}
                    />
                  </ScrollShadow>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

interface IMeetingList {
  meetingUUID: string
  id: number
  createdAt: string
  user: {
    firstname: string
    lastname: string
    email: string
    agency: {
      id: number
      name: string
    }
  }
}

interface IMeeting {
  meetingUUID: string
  summary: string
  transcription: {
    conversation: ITranscription[]
  }
}

interface ITranscription {
  text: string
  source: 'you' | 'speaker' | 'assistant'
}
