import React, { useEffect, useRef, useState } from 'react'

import Markdown from 'react-markdown'

import { Icon } from '@iconify/react'
import { Button } from '@nextui-org/button'
import { Textarea, ScrollShadow, Chip } from '@nextui-org/react'
import { Sparkles } from 'lucide-react'
import { useParams } from 'react-router-dom'

import Mixpanel from 'src/lib/mixpanel'

import MessageCard from 'src/components/ai/message-card'
import PromptInputWithBottomActions, {
  PromptInputWithBottomActionsRef,
} from 'src/components/ai/prompt-input-with-bottom-actions'

export function URSChat({
  askAI,
  urd,
  setLoading,
  variant='dark'
}: {
  askAI: (prompt: string) => Promise<string>
  urd: any
  setLoading: (value: boolean) => void,
  variant: 'dark' | 'light'
}) {
  const [initialized, isInitialized] = useState<boolean>(false)
  const [thinking, setThinking] = useState<boolean>(false)
  const [allMsgs, setAllMsgs] = useState<ITranscriptionMessage[]>([])
  const [newMsg, setNewMsg] = useState<string>('')
  const scrollPane = useRef(null)
  const [editLastMessage, setEditLastMessage] = useState<boolean>(false)
  const [editedMessage, setEditedMessage] = useState<string>('')
  const { ursUUID } = useParams()
  const inputRef = useRef<HTMLInputElement>(null)
  const promptInputRef = useRef<PromptInputWithBottomActionsRef>(null)

  // Scrolling to an end is not supported by NextUI but this function does the trick
  const scrollToEnd = () => {
    setTimeout(() => {
      scrollPane?.current?.scrollIntoView && scrollPane.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, 200)
  }

  // Kick if off
  const initialize = async () => {
    const prevDiscussion =
      urd?.chat?.map((item: any) => ({
        source: item.source,
        value: item.content,
        date: item.date,
      })) ?? []
    setAllMsgs(prevDiscussion)
    isInitialized(true) // wait till we have an api response to avoid flickering
    scrollToEnd()
  }

  useEffect(() => {
    scrollToEnd()
  }, [allMsgs])

  const getUpdate = async (message?: string) => {
    if (newMsg || message) {
      setLoading(true)
      setThinking(true)
      setAllMsgs((prevTranscription: any) => [...prevTranscription, { source: 'user', value: message ?? newMsg }])
      try {
        const result = await askAI(message ?? newMsg)
        if (inputRef.current) {
          inputRef.current.value = ''
        }
        setThinking(false)
        setAllMsgs((prevTranscription: any) => {
          return [...prevTranscription, { source: 'ai', value: result }]
        })
        setLoading(false)
        scrollToEnd()
      } catch (error) {
        setLoading(false)
        setThinking(false)
      }
    }
  }

  const handleStromAIMessageSend = () => {
    // Track submission interaction (via Enter key or button click)
    Mixpanel.track("Strom AI chat message sent", {
      "input_length": inputRef.current?.value?.length || 0, // Track input length
    });
  
    getUpdate();
    promptInputRef.current?.resetPrompt?.();
  };

  useEffect(() => {
    initialize() // in an extra function because async/await is not working in useEffect
  }, [ursUUID])
  return (
    <>
      {initialized && (
        <div className="flex flex-col grow min-h-0">
          <h2 className={'text-xl font-bold text-black'}>Strom AI chat</h2>
          <p className={'text-sm text-gray-500 leading-5'}>
            Chat with Strom AI to transform your entire document—adapt, add, or remove full sections. Whatever you need, our AI can make it happen.
          </p>
          <ScrollShadow className="flex-2 mb-3" hideScrollBar size={100} id="msgs">
            <div ref={scrollPane}>
              {allMsgs?.map(
                (msg: ITranscriptionMessage, index: number) =>
                  msg.value?.split('@@URD@@')[0].trim().length > 0 && (
                    <div className="my-2" key={`message_${index}`}>
                      {editLastMessage && index === allMsgs?.length - 1 ? (
                        <>
                          <Textarea
                            minRows={1}
                            maxRows={200}
                            onChange={(e) => setEditedMessage(e.target.value)}
                            defaultValue={msg.value
                              ?.split('@@URD@@')[0]
                              .trim()
                              ?.replace(/@@QUESTION@@/g, '')
                              ?.split('\n')
                              ?.slice(1, msg.value?.split('\n').length - 1)
                              .join('\n')
                            }
                            className="py-0"
                            color={msg.source == 'ai' ? 'default' : 'default'}
                          />
                        </>
                      ) : msg.source === 'ai' && msg.value?.startsWith('```') ? (
                        <div
                          key={`message_${index}`}
                          className={'relative rounded-medium border-small border-divider p-3 flex-wrap'}
                        >
                          <Markdown
                            children={
                              msg.value.startsWith('```markdown')
                                ? msg.value
                                    ?.split('@@URD@@')[0]
                                    ?.replace(/@@QUESTION@@/g, '')
                                    ?.split('@@EXAMPLE@@')[0]
                                    ?.split('\n')
                                    ?.slice(1, msg.value?.split('\n').length - 1)
                                    .join('\n')
                                : msg.value
                                    .split('@@URD@@')[0]
                                    ?.replace(/@@QUESTION@@/g, '')
                                    ?.split('@@EXAMPLE@@')[0]
                            }
                            className={`reactMarkDown flex-wrap no-scrollbar`}
                          />
                          {msg.value?.includes('@@EXAMPLE@@') ? (
                            <>
                              <div className="flex justify-center">
                                <Chip
                                  startContent={<Icon icon="solar:lightbulb-bolt-outline" />}
                                  variant="flat"
                                  color="secondary"
                                  size="sm"
                                  style={{backgroundColor: "#5343AE", color: "#fff"}}
                                  className="px-5 mt-5"
                                >
                                  SUGGESTIONS
                                </Chip>
                              </div>
                              {msg.value
                                ?.split('@@URD@@')[0]
                                ?.split('@@EXAMPLE@@')[1]
                                .split('\n')
                                ?.filter((item) => item.length > 5)
                                ?.map((item: any, indexMessage: number) => {
                                  const example = item.startsWith('- ') ? item.replace('- ', '') : item;
                                  return (
                                    <Button
                                      key={`message_${index}_example_${indexMessage}`}
                                      color="secondary"
                                      variant={'bordered'}
                                      className="mt-2 w-full text-left justify-start flex-wrap whitespace-break-spaces h-auto pt-2 pb-2"
                                      onClick={() => {
                                        getUpdate(example)
                                        promptInputRef.current?.resetPrompt?.()
                                      }}
                                      isDisabled={thinking}
                                      // isLoading={thinking}
                                      size="md"
                                      radius="md"
                                    >
                                      <span>{example}</span>
                                    </Button>
                                  )
                                })}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div className={`chat ${(msg.source as string) ? 'ml-0' : 'mr-0'}`} key={msg.id}>
                          <MessageCard
                            key={index}
                            // attempts={index === 1 ? 2 : 1}
                            avatar={(msg.source as string) === 'ai' ? 'Strom AI' : 'You'}
                            currentAttempt={index === 1 ? 2 : 1}
                            message={msg.value}
                            messageClassName={msg.source === 'user' ? 'border text-black bg-transparent' : 'border text-black bg-transparent w-auto'}
                            className={msg.source === 'ai' ? 'items-end' : ''}
                            showFeedback={false}
                            messageContainerClass={'w-auto'}
                            //showFeedback={(msg.source as string) === 'ai'}
                          />
                        </div>
                      )}
                      {index == allMsgs.length - 1 && msg.source === 'ai' && (
                        <div className="flex" style={{display: 'none'}}>
                          <Button isIconOnly radius="full" size="sm" variant="light">
                            <Icon className="text-lg text-default-600" icon="gravity-ui:copy" />
                          </Button>
                          <Button
                            isIconOnly
                            radius="full"
                            size="sm"
                            variant="light"
                            onClick={() => {
                              if (editLastMessage) {
                                const newAllMsg = [...allMsgs]
                                newAllMsg[index] = {
                                  source: 'ai',
                                  value: '```markdown+\n' + editedMessage + '\n```',
                                }
                                setAllMsgs(newAllMsg)
                                setEditLastMessage(false)
                              } else {
                                setEditedMessage(
                                  msg.value
                                    ?.split('\n')
                                    ?.slice(1, msg.value?.split('\n').length - 1)
                                    .join('\n'),
                                )
                                setEditLastMessage(true)
                              }
                            }}
                          >
                            {editLastMessage ? (
                              <Icon className="text-lg text-default-600" icon="gravity-ui:check" />
                            ) : (
                              <Icon className="text-lg text-default-600" icon="gravity-ui:pencil-to-square" />
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  ),
              )}
            </div>
          </ScrollShadow>
          <div className="flex flex-col flex-1 justify-end sticky bottom-0">
            <PromptInputWithBottomActions
              inputRef={inputRef}
              ref={promptInputRef}
              onSubmit={handleStromAIMessageSend}
              onChange={(e) => {
                setNewMsg(e.target.value); /* console.log(e.target.value); */
              }}
              variant={variant}
            />
            <Button
              color="secondary"
              variant={variant === 'dark' ? 'bordered' : 'solid'}
              className="mt-2"
              onClick={handleStromAIMessageSend}
              startContent={thinking ? '' : <Sparkles />}
              isDisabled={thinking}
              isLoading={thinking}
              size="md"
              radius="md"
            >
              {thinking ? 'Thinking…' : 'Send'}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

interface ITranscriptionMessage {
  value: string
  source: 'ai' | 'user'
}