import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Image,
  Card,
  CardBody,
  CardFooter,
  Chip,
  Skeleton,
  ScrollShadow,
  Progress,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from '@nextui-org/react'
import { useSocketContextData } from 'src/context/socket'
import CreateProjectPrompt from 'src/components/create-project-prompt'
import api from 'src/lib/api'
import { Icon } from '@iconify/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useWorkspaceContextData } from 'src/context/workspace'

export default function Projects() {
  const { t } = useTranslation()
  const [meetings, setMeetings] = useState<any>({})
  const { client } = useParams()
  const clientUuid: string | undefined = client

  const statuses = [
    { key: 'created', title: 'Project drafts', className: 'text-white text-xl' },
    { key: 'inProgress', title: 'AI processing', className: 'text-white text-xl' },
    { key: 'ready', title: 'Ready to review & deploy', className: 'text-white text-xl' },
  ]
  const [initialized, setInitialized] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { io, isSocketConnected } = useSocketContextData()
  const { activeWorkspace } = useWorkspaceContextData()
  const getMeetings = async () => {
    setLoading(true)
    const res = await api.get(`/meetings/list/${activeWorkspace}?orgId=${clientUuid}`)
    setMeetings(res.data)
    setInitialized(true)
    setLoading(false)
  }
  useEffect(() => {
    getMeetings()
  }, [activeWorkspace])
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const hasMeeting = () =>
    meetings?.['created']?.length > 0 || meetings?.['inProgress']?.length > 0 || meetings?.['ready']?.length > 0

  const renderChip = (param: string) => {
    switch (param) {
      case 'created':
        return (
          <Chip color="secondary" variant="dot">
            Draft
          </Chip>
        )
      case 'inProgress':
        return (
          <Chip color="warning" variant="dot">
            Processing
          </Chip>
        )
      case 'error':
        return (
          <Chip color="danger" variant="dot">
            Error
          </Chip>
        )
      case 'running':
        return (
          <Chip color="secondary" variant="dot">
            Running
          </Chip>
        )
      case 'ready':
        return (
          <Chip color="success" variant="dot">
            Ready
          </Chip>
        )
      default:
        return ''
    }
  }

  // Create new client modal and listen to "new" in the URL
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    if (window.location.pathname === '/clients/new') {
      handleOpen()
    }
  }, [])

  const handleOpen = () => {
    onOpen()
  }

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <Modal backdrop="blur" isOpen={isOpen} onClose={onClose} scrollBehavior="outside" size="5xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Create new client</ModalHeader>
              <ModalBody>
                <CreateProjectPrompt defaultOrg={client} />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <div className="flex flex-col h-screen p-3 grow">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Projects</h1>
              <p className="text-small text-default-400 lg:text-medium">
                View, organize, and track progress across all projects.
              </p>
            </div>
            {!loading && (
              <Button
                // onClick={() => setShowCreateForm(!showCreateForm)}
                onClick={() => handleOpen()}
                color="secondary"
                size="md"
                radius="md"
                startContent={<Icon className="flex-none" icon="solar:add-circle-linear" width={20} />}
              >
                Create new project
              </Button>
            )}
          </header>
        </div>

        <div className="flex gap-3 h-full grow max-h-full px-4 2xl:px-8">
          {loading ? (
            <div className="flex flex-col items-center place-content-center w-full">
              <Icon icon="svg-spinners:bouncing-ball" width={50} />
              <p className="text-xl text-center mt-2">Loading projects</p>
            </div>
          ) : !hasMeeting() ? (
            <div className="flex flex-col items-center place-content-center w-full">
              <div className="flex flex-col items-center place-content-center">
                <Image
                  src="/img/empty-state-projects.png"
                  alt="no projects available"
                  width={400}
                  radius="none"
                  className="mb-8"
                />
                <p className="text-2xl text-center font-semibold mb-2">No projects available</p>
                <p className="text-base text-center">
                  Create your first project by clicking <br />
                  on the button at the top right
                </p>
              </div>
            </div>
          ) : (
            <>
              {statuses.map((status) => (
                <div className="flex flex-col w-1/3" key={status.key}>
                  <p className={`text-2xl pb-2 ${status.className}`}>{status.title}</p>
                  <div className="flex flex-col grow min-h-0 max-h-full border-1 rounded-lg border-stone-800 p-3">
                    <ScrollShadow className="space-y-3" hideScrollBar size={100}>
                      {!initialized || !meetings ? (
                        <Card className="bg-stone-800" radius="sm">
                          <Skeleton className="rounded-md">
                            <CardBody className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
                              <div className="flex flex-col col-span-6 md:col-span-8 justify-between">
                                <p className="text-xl">&nbsp;</p>
                                <Chip className="mt-1" color="secondary" size="sm" variant="flat">
                                  &nbsp;
                                </Chip>
                              </div>
                            </CardBody>
                          </Skeleton>
                        </Card>
                      ) : (
                        meetings[status.key]?.map((project: any) => (
                          <>
                            <Card
                              className="w-full border-small border-default-100 rounded-md p-3"
                              shadow="sm"
                              isPressable
                              onPress={() => navigate(`/project/${project.meetingUUID}`)}
                              key={project.meetingUUID}
                            >
                              <CardBody className="px-4 pb-1">
                                <div className="flex items-center justify-between gap-2">
                                  <div className="flex max-w-[80%] flex-col gap-1">
                                    <p className="text-medium font-medium line-clamp-1">
                                      {project.title?.length > 0 ? project.title : project.meetingUUID}
                                    </p>
                                    <p className="text-small text-default-500">
                                      Last update:&nbsp;
                                      {new Date(project.updatedAt).toLocaleString('en-EN', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })}
                                    </p>
                                  </div>
                                </div>
                                <p className="pt-4 text-small text-default-500">
                                  {project.summary
                                    ? project.summary
                                        ?.split('# User story')?.[0]
                                        ?.split('Summary')
                                        ?.pop()
                                        ?.substr(0, 120)
                                    : 'Description not available yet...'}
                                </p>
                              </CardBody>
                              <CardFooter className="flex-col gap-2">
                                <div className={'flex-row justify-between gap-2 flex w-full'}>
                                  <Button
                                    size="sm"
                                    variant="faded"
                                    onPress={() => navigate(`/project/${project.meetingUUID}`)}
                                  >
                                    Configure
                                  </Button>
                                  {renderChip(
                                    project?.currentStep < project.totalSteps && status.key === 'ready'
                                      ? 'running'
                                      : status.key,
                                  )}
                                </div>
                                {project.totalSteps > 0 && (
                                  <Progress
                                    showValueLabel
                                    classNames={{
                                      label: 'font-medium',
                                      indicator: 'bg-gradient-to-r from-primary-400 to-secondary-500',
                                      value: 'text-foreground/60',
                                    }}
                                    label="Progress"
                                    value={(100 / project.totalSteps) * project.currentStep}
                                  />
                                )}
                              </CardFooter>
                            </Card>
                          </>
                        ))
                      )}
                    </ScrollShadow>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}
