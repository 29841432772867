import mixpanel from 'mixpanel-browser'

let mixpanelDebugStatus = false;
if (process.env.NODE_ENV === 'development') {
  mixpanelDebugStatus = true;
}

mixpanel.init('7f0a04a424de8ab3c9094d0ce39b7dc2', {
  // api_host: 'https://api-eu.mixpanel.com',
  cookie_domain: '.strom.ai',
  persistence: 'localStorage',
  track_pageview: "full-url",
  debug: mixpanelDebugStatus,
})

// Immediately initialize cross-domain tracking
const initialize = () => {
  const params = new URLSearchParams(window.location.search);
  const mpid = params.get('mpid');

  if (mpid) {
    console.log(`Initializing cross-domain tracking with mpid: ${mpid}`);
    mixpanel.identify(mpid);
    
    // Clean up the URL
    params.delete('mpid');
    const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : '');
    window.history.replaceState({}, '', newUrl);
  }
};

// Call initialize immediately
initialize();

console.log(`track app`)

const Mixpanel = {
  // Keep initialize method for potential manual re-initialization if needed
  initialize,

  // Get current distinct_id for cross-domain tracking
  getDistinctId: () => {
    return mixpanel.get_distinct_id();
  },

  // Append distinct_id to a URL for cross-domain tracking
  appendDistinctId: (url: string): string => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('mpid', mixpanel.get_distinct_id());
      return urlObj.toString();
    } catch (error) {
      console.error('Error appending distinct_id to URL:', error);
      return url;
    }
  },

  // Identify a user in session
  login: (userId: number) => {
    console.log(`track login ${userId}`)
    mixpanel.alias(`${userId}`) // Alias/merge the current anonymous Mixpanel distinct_id (that was set automatically by Mixpanel during mixpanel.init) to the user's unique ID
    mixpanel.identify(`${userId}`) // Identify the user with their unique ID
  },

  // Create a user during signup
  signup: (data: any) => {
    console.log(`track signup`, data)
    mixpanel.people.set(data)

    // Call trackUTMAndSetProfile after setting the user's profile
    //Mixpanel.trackUTMAndSetProfile();
  },

  // Alias another user later on
  alias: (userId: number) => {
    console.log(`track alias ${userId}`)
    mixpanel.alias(`${userId}`)
  },

  // Logout an user
  logout: () => {
    console.log(`track logout`)
    mixpanel.reset()
  },

  // General tracking method
  track: (prop: string, info: any = {}) => {
    // Retrieve and attach user ID from local storage if available
    const userString = localStorage.getItem('user');
    if (userString) {
      try {
        const user = JSON.parse(userString);
        // Include user ID in the track event properties
        info = { ...info, user_id: user.uuid };
      } catch (err) {
        console.error('Error parsing user data from local storage', err);
      }
    }

    console.log(`event track "${prop}" with properties: `, JSON.stringify(info, null, 2)); // Log info in a readable format
    mixpanel.track(`${prop}`, info);
  },

  // Track UTM parameters and set them in Mixpanel
  trackUTMAndSetProfile: () => {
    const utmParams = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_id",
      "utm_source_platform",
      "utm_term",
      "utm_content",
    ];

    // Function to retrieve UTM data from URL or localStorage
    const getUTMData = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const utmData: { [key: string]: string } = {};
  
      utmParams.forEach((param) => {
        const value = urlParams.get(param) || localStorage.getItem(param);
        if (value) {
          utmData[param] = value;
          localStorage.setItem(param, value);
        }
      });
  
      return Object.keys(utmData).length > 0 ? utmData : null;
    };

    // Retrieve UTM data
    const utmData = getUTMData();

    // If UTM data exists, track and set the user profile
    if (utmData) {
      console.log(`Tracking UTM data:`, utmData);

      // Track the UTM parameters as an event
      mixpanel.track("UTM Data Captured", utmData);

      // Set the UTM parameters in the user profile
      mixpanel.people.set(utmData);
    } else {
      console.log("No UTM data found in URL or localStorage");
    }
  },

  // Track a button click
  btn: (screen: string, action: string, info?: any) => {
    console.log(`track btn ${action} on scr ${screen}`)
    mixpanel.track(`btn_${screen}_${action}`, info)
  },

  // Track a screen/page view
  scr: (screen: string, info?: any) => {
    console.log(`track scr ${screen}`)
    mixpanel.track(`scr_${screen}`, info)
  },

  // Track an action
  // act: (screen: string, action: string, info: any = {}) => {
  act: (action: string, info: any = {}) => {
    // Set a default value for 'type' if not provided
    const defaultInfo = { ...info, type: info?.type || 'success' };
    console.log(`track act ${action} on ${screen}`);
    mixpanel.track(`${action}`, defaultInfo);
    // mixpanel.track(`act_${screen}_${action}`, defaultInfo);
  },

  // Track an error
  err: (screen: string, action: string, error: string, info?: any) => {
    console.log(`track err ${action} on ${screen}`)
    mixpanel.track(`act_${screen}_${action}_error`, { ...info, type: 'error', error: error })
  },
}

export default Mixpanel
