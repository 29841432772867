import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Code,
  Image,
  Listbox,
  ListboxItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Progress,
  ScrollShadow,
  Textarea,
  useDisclosure,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { Check, CheckCircle, HardDriveUpload, RedoDot, Save } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSocketContextData } from 'src/context/socket'
import ReactDiffViewer from 'react-diff-viewer'

import api from 'src/lib/api'
import { StromSalesforceAgent } from 'src/components/ai/strom-salesforce-agent'
import { CodeEditor } from 'src/components/ai/code-editor'
import { useWorkspaceContextData } from 'src/context/workspace'
import toast from "react-hot-toast";
import CountDownProject from "src/components/counter";

export default function Insights() {
  const [meeting, setMeeting] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [codeChange, setCodeChange] = useState<string>('')
  const [currentStepError, setCurrentStepError] = useState<string>('')
  const [textInput, setTextInput] = useState<string>()
  const [storedTextInput, storeTextInput] = useState<string>()
  const [implementationSteps, setImplementationSteps] = useState<IImplementation[]>([])
  const [summary, setSummary] = useState<string>('')
  const [initialized, setInitizalied] = useState<boolean>(false)
  const [stepList, setStepList] = useState<
    {
      title: string
      path: string | null
      content: string
      type: string
    }[]
  >([])
  const [[page, direction], setPage] = React.useState([0, 0])
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [visibleStep, setVisibleStep] = useState<number>(0)
  const [nextStep, setNextStep] = useState<number>(0)
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false)
  const { t } = useTranslation()
  const { meetingUUID } = useParams()
  const { io } = useSocketContextData()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenFinal, onOpen: onOpenFinal, onClose: onCloseFinal } = useDisclosure()
  const { activeWorkspace } = useWorkspaceContextData()
  const loadMeeting = async () => {
    const res = await api.get(`/meetings/${activeWorkspace}/${meetingUUID}`)
    setMeeting(res.data)
    setInitizalied(true)
  }
  const saveTextInput = () => {
    console.log('emit textInput', textInput)
    io.emit('updateTextInput', { meetingUUID, textInput })
    storeTextInput(textInput)
  }

  const extractSteps = (
    text: string,
  ): {
    type: string
    path: string | null
    content: string
    title: string
  }[] => {
    const stepRegex = /```(.*?)```/gs
    const steps: {
      type: string
      path: string | null
      content: string
      title: string
    }[] = []
    let match

    while ((match = stepRegex.exec(text)) !== null) {
      const step = match[1].trim()
      const typeMatch = step.match(/(file|exec)/)

      if (typeMatch) {
        const type = typeMatch[1]
        let path = null
        let title = ''
        let content = ''

        if (type === 'file') {
          const pathMatch = step.match(/file\[(.*?)\]\[(.*?)\]/)
          path = pathMatch?.[1] ?? ''
          title = pathMatch?.[2] ?? ''
          content = step.replace(/file\[(.*?)\]\[(.*?)\]/, '').trim()
        } else if (type === 'exec') {
          path = null
          const pathMatch = step.match(/exec\[(.*?)\]/)
          title = pathMatch?.[1] ?? ''
          content = step.replace(/exec\[(.*?)\]/, '').trim()
        }

        steps.push({ type, path, content, title })
      }
    }

    return steps
  }
  const generateSteps = async (summary: string) => {
    setLoading(true)
    try {
      const res = await api.post(`/meetings/${meetingUUID}/steps`, {
        summary,
      })
      const text = res.data
      const steps = extractSteps(text)
      if (steps.length > 0) {
        setStepList(steps)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const paginate = React.useCallback((newDirection: number) => {
    setPage((prev) => {
      const nextPage = prev[0] + newDirection

      if (nextPage < 0 || nextPage > 3) return prev

      return [nextPage, newDirection]
    })
  }, [])

  const onChangePage = React.useCallback((newPage: number) => {
    setPage((prev) => {
      if (newPage < 0 || newPage > 3) return prev
      const currentPage = prev[0]

      return [newPage, newPage > currentPage ? 1 : -1]
    })
  }, [])

  const onBack = React.useCallback(() => {
    paginate(-1)
  }, [paginate])

  const onNext = React.useCallback(() => {
    paginate(1)
  }, [paginate])
  const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time))
  const executeStep = async () => {
    let error = false
    let message = ''
    let stepId = currentStep
    while (stepId < stepList.length && !error) {
      const output = await io.emitWithAck('execute', stepList[stepId])
      if (output.success) {
        document
          .querySelectorAll(`[tab-key="tab_${stepId + 1}"]`)
          ?.pop?.()
          ?.click?.()
        setCurrentStep(stepId + 1)
        stepId++
        setErrorMessage('')
        await sleep(1000)
      } else {
        error = true
        message = output.output
        console.log(output)
        setErrorMessage(message)
      }
    }
    return message
  }
  const startDeployment = () => {
    toast('Deployment is now running in the background!', {
      icon: '👏',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
    io.emit('startDeploy', {
      meetingUUID,
      token: localStorage.getItem('accessToken'),
    })
  }
  const [updating, setUpdating] = useState<boolean>(false)
  const saveCodeChange = async () => {
    setUpdating(true)
    const stepsCopy = [...stepList]
    stepsCopy[visibleStep].content = codeChange
    setStepList(stepsCopy)
    const stepsString = stepsCopy
      .map((step) => {
        if (step.type === 'exec') {
          return '```exec[' + step.title + ']\n' + step.content + '\n```'
        } else {
          return '```file[' + step.path + '][' + step.title + ']\n' + step.content + '\n```'
        }
      })
      .join('\n')
    const response = await io.emitWithAck('updateSteps', {
      meetingUUID,
      stepsString,
    })
    setShowSaveModal(false)
    if (response.id) {
      setCurrentStepError('')
      setMeeting(response)
    }
    setUpdating(false)
  }
  const updateSocketStep = (data: any) => {
    const {step} = data
    setCurrentStep(step ?? currentStep)
  }
  useEffect(() => {
    if (activeWorkspace) {
      loadMeeting()
    }
  }, [activeWorkspace])

  useEffect(() => {
    if (meeting?.steps && stepList.length === 0) {
      const steps = extractSteps(meeting?.steps)
      setStepList(steps)
      if (meeting.currentStep > -1) {
        setCurrentStep(meeting.currentStep)
        setVisibleStep(meeting.currentStep)
      }
      if (meeting.error?.length > 0) {
        setCurrentStepError(meeting.error)
      }
    }
  }, [meeting])

  useEffect(() => {
    if (visibleStep > -1) {
      setCodeChange(stepList?.[visibleStep]?.content ?? '')
    }
  }, [visibleStep])

  useEffect(() => {
    if (io) {
      io.on('projectStep', updateSocketStep)
    }
  }, [io])

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div
        className="flex flex-row w-full content-stretch h-screen"
        // style={{
        //   height: 'calc(90vh)',
        //   backgroundSize: '40px 40px',
        //   backgroundImage: 'radial-gradient(#474747 1px, transparent 0)',
        //   backgroundPosition: '-5px -5px',
        // }}
      >
        {initialized ? (
          <div className="flex w-full h-full gap-2 p-2 overflow-hidden">
            <div className="flex flex-col w-1/3 2xl:basis-1/4 px-8 py-2 md:px-3 rounded-medium border-small border-divider h-full">
              <p className="text-lg mb-3">Project requirements</p>
              <StromSalesforceAgent meeting={meeting} generateSteps={generateSteps} setLoading={setLoading}/>
            </div>
            <div className="flex w-2/3 2xl:basis-3/4 h-full gap-2">
              {loading ? (
                <>
                  <div className="flex flex-1 flex-col justify-center content-center items-center">
                    <Image
                      alt="ai loading animation"
                      height={60}
                      radius="sm"
                      src="/img/ai-loading-lottie.gif"
                      width={300}
                    />
                    <p className="text-center text-lg mt-2 max-w-[300px]">
                      Generating components, code, and Salesforce configuration ...
                    </p>
                  </div>
                </>
              ) : stepList.length > 0 ? (
                <>
                  <div className="flex flex-col w-1/2 2xl:w-1/4 px-8 py-2 md:px-3 rounded-medium border-small border-divider">
                    <p className="text-lg mb-3">AI generated steps</p>
                    <div className="flex items-center gap-3 px-2 pb-2">
                      <div className="flex h-10 w-10 p-2 flex-none items-center justify-center rounded-full bg-gradient-to-br from-secondary-300 to-primary-500">
                        <Icon className="text-white" icon="ph:sparkle" width={20} onClick={executeStep} />
                      </div>
                      <Progress
                        showValueLabel
                        classNames={{
                          label: 'font-medium',
                          indicator: 'bg-gradient-to-r from-primary-400 to-secondary-500',
                          value: 'text-foreground/60',
                        }}
                        label="Progress"
                        value={(100 / stepList.length) * currentStep}
                      />
                    </div>
                    <ScrollShadow className="grow mb-3" hideScrollBar size={100}>
                      <Listbox hideSelectedIcon aria-label="Onboarding checklist" items={stepList} variant="flat">
                        {stepList.map(
                          (
                            item: {
                              title: string
                              path: string | null
                              content: string
                              type: string
                            },
                            index: number,
                          ) => (
                            <ListboxItem
                              key={index}
                              classNames={{
                                base: 'w-full px-0 min-h-[50px] gap-2',
                                wrapper: 'truncate',
                                title: 'text-small font-medium w-full',
                                description: 'text-xs',
                              }}
                              onPress={() => {
                                if (
                                  stepList?.length > 0 &&
                                  stepList?.[visibleStep].content !== codeChange &&
                                  codeChange.length > 0
                                ) {
                                  setShowSaveModal(true)
                                  setNextStep(index - 1)
                                } else {
                                  setVisibleStep(index - 1)
                                }
                              }}
                              startContent={
                                <div className="flex flex-none">
                                  {index < currentStep ? (
                                    <Icon className="text-secondary" icon="solar:check-circle-bold" width={30} />
                                  ) : currentStepError && index === currentStep ? (
                                    <Icon className="text-secondary" icon="line-md:uploading-loop" width={30} />
                                  ) : (
                                    <Icon
                                      className="text-default-400"
                                      icon="solar:round-alt-arrow-right-bold"
                                      width={30}
                                    />
                                  )}
                                </div>
                              }
                              title={item.title}
                              description={'Step ' + ++index}
                            >
                              {/* <div className="flex gap-2 items-center">
                                <div className="flex flex-col w-full">
                                  <span className="text-small truncate">{item.title}</span>
                                  <span className="text-tiny text-default-400">{'Step ' + ++index}</span>
                                </div>
                              </div> */}
                            </ListboxItem>
                          ),
                        )}
                      </Listbox>
                    </ScrollShadow>
                    <div className="flex">
                      <Button color="secondary" startContent={<RedoDot />} size="md" radius="md" fullWidth isDisabled>
                        Next step
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 2xl:w-3/4 px-8 py-2 md:px-3 rounded-medium border-small border-divider">
                    <CountDownProject project={meeting} />
                  </div>

                  <Card className="min-w-[520px] max-w-[520px] py-1 md:py-4" style={{ display: 'none' }}>
                    <CardHeader className="flex items-center gap-3 px-4 pb-0 pt-3 md:px-10 md:pt-5">
                      <div className="flex h-14 w-14 flex-none items-center justify-center rounded-full bg-gradient-to-br from-secondary-300 to-primary-500">
                        <Icon className="text-white" icon="ph:sparkle" width={30} />
                      </div>
                      <Progress
                        showValueLabel
                        classNames={{
                          label: 'font-medium',
                          indicator: 'bg-gradient-to-r from-primary-400 to-secondary-500',
                          value: 'text-foreground/60',
                        }}
                        label="SFDC Sandbox Generation with AI"
                        value={(100 / stepList.length) * currentStep}
                      />
                    </CardHeader>
                    <CardBody className="px-1 pt-3 sm:px-2 md:px-6">
                      <Listbox hideSelectedIcon aria-label="Onboarding checklist" items={stepList} variant="flat">
                        {stepList.map(
                          (
                            item: {
                              title: string
                              path: string | null
                              content: string
                              type: string
                            },
                            index: number,
                          ) => (
                            <ListboxItem
                              key={index}
                              classNames={{
                                base: 'w-full px-2 md:px-4 min-h-[70px] gap-2',
                                title: 'text-medium font-medium',
                                description: 'text-small',
                              }}
                              endContent={
                                <div className="flex flex-none">
                                  {index < currentStep ? (
                                    <Icon className="text-secondary" icon="solar:check-circle-bold" width={30} />
                                  ) : (
                                    <Icon
                                      className="text-default-400"
                                      icon="solar:round-alt-arrow-right-bold"
                                      width={30}
                                    />
                                  )}
                                </div>
                              }
                              startContent={
                                <div className="item-center flex rounded-medium border border-divider p-2">
                                  <Icon className="text-secondary" icon={'carbon:ai'} width={24} />
                                </div>
                              }
                              title={item.title}
                              // description={item.content.substring(0, 40) + "..."}
                              description={'Step ' + ++index}
                            />
                          ),
                        )}
                      </Listbox>
                    </CardBody>
                    <CardFooter className="flex-col gap-2 shrink-0">
                      <Code color="success" className="w-full">
                        {stepList?.[currentStep]?.content}
                      </Code>
                      <div className="flex w-full justify-end">
                        {currentStepError.length > 0 && (
                          <Code
                            color="danger"
                            className="flex flex-1 mr-4"
                            style={{
                              maxHeight: 60,
                              width: '35%',
                              overflow: 'scroll',
                            }}
                          >
                            {currentStepError}
                          </Code>
                        )}
                        <Button
                          style={{ borderColor: '#E4E4E7' }}
                          radius={'full'}
                          className={'bg-transparent border-1 p-4'}
                          onClick={executeStep}
                        >
                          <Check />
                          {/* <span
                            style={{
                              fontSize: 16,
                              fontWeight: '600',
                              color: '#369791',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {executing
                              ? 'Executing'
                              : currentStep === stepList.length - 1
                                ? 'Deploy'
                                : curretnStepError.length > 0
                                  ? 'Retry (AI Fix)'
                                  : 'Execute'}{' '}
                            {executing ? (
                              <>
                                <Spinner size={'sm'} color={'secondary'} />
                              </>
                            ) : (
                              <></>
                            )}
                          </span> */}
                        </Button>
                        {/* <Button fullWidth className="border-small border-white/20 bg-white/10 text-white">
                          Get tickets now
                        </Button> */}
                      </div>
                    </CardFooter>
                  </Card>
                  {/* <Card className={'flex flex-1'}>
                    <CardHeader className="flex items-center gap-3 px-4 pb-0 pt-3 md:px-10 md:pt-5">
                      <div
                        onClick={() => executeStep()}
                        className="cursor-pointer flex h-14 w-14 flex-none items-center justify-center rounded-full bg-gradient-to-br from-secondary-300 to-primary-500"
                      ></div>
                      <Progress
                        showValueLabel
                        classNames={{
                          label: 'font-medium',
                          indicator: 'bg-gradient-to-r from-primary-400 to-secondary-500',
                          value: 'text-foreground/60',
                        }}
                        label="SFDC Sandbox Generation with AI"
                        value={(100 / stepList.length) * currentStep}
                      />
                    </CardHeader>
                    <CardBody>
                      <Tabs aria-label="Options" selectedKey={`tab_${currentStep}`} isVertical>
                        {stepList.map((step, index) => (
                          <Tab
                            id={`tab_${index}`}
                            key={`tab_${index}`}
                            title={step.title}
                            className={'flex flex-grow flex-col'}
                          >
                            {step.type === 'exec' ? (
                              <>
                                <Terminal
                                  name={step.title}
                                  height="400px"
                                  colorMode={ColorMode.Dark}
                                  startingInputValue={step.content}
                                  onInput={(terminalInput) =>
                                    console.log(`New terminal input received: '${terminalInput}'`)
                                  }
                                >
                                  {errorMessage && currentStep === index && (
                                    <TerminalOutput>
                                      <span style={{ color: '#d9515d' }}>{errorMessage}</span>
                                    </TerminalOutput>
                                  )}
                                </Terminal>
                              </>
                            ) : (
                              <>
                                <CodeEditor code={step.content} />
                              </>
                            )}
                            {step.type !== 'exec' && (
                              <div>
                                <Button
                                  color="secondary"
                                  className="mt-3"
                                  endContent={<WandIcon />}
                                  onClick={console.log}
                                  size="lg"
                                >
                                  {step.type === 'exec' ? 'Execute' : 'Write File'}
                                </Button>
                              </div>
                            )}
                          </Tab>
                        ))}
                      </Tabs>
                    </CardBody>
                  </Card> */}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center content-center items-center">
              <Image alt="ai loading animation" height={40} radius="sm" src="/img/ai-loading-lottie.gif" width={200} />
              Retrieving project details
            </div>
          </>
        )}
      </div>
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                You can manually add text to enrich the context of the meeting
              </ModalHeader>
              <ModalBody>
                <Textarea
                  // autoFocus
                  minRows={20}
                  placeholder="Just copy & paste text here from other conversations, from ticket descriptions, or manually add specifications to improve the results."
                  onChange={(e) => setTextInput(e.target.value)}
                  defaultValue={textInput}
                />
              </ModalBody>
              <ModalFooter className="flex flex-row justify-end items-center">
                <p className="mr-12">
                  <i>{textInput && textInput === storedTextInput ? 'Text saved' : 'Text not saved yet'}</i>
                </p>
                <Button
                  onPress={() => {
                    setTextInput(storedTextInput)
                    onClose()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  onPress={() => {
                    saveTextInput()
                    onClose()
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenFinal} onClose={onCloseFinal}>
        <ModalContent>
          {(onCloseFinal) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Export configuration for deployment</ModalHeader>
              <ModalBody>
                <Progress
                  size="md"
                  isIndeterminate
                  aria-label="Action steps to deploy"
                  className="max-w-md mb-3"
                  color="secondary"
                />
                {implementationSteps?.map((data: any, index) => (
                  <Button
                    variant="faded"
                    disabled
                    startContent={<CheckCircle color="#67D0CB" />}
                    key={index}
                    className="text-left"
                  >
                    {data.title}
                  </Button>
                ))}
              </ModalBody>
              <ModalFooter className="flex flex-row justify-center items-center">
                <Button color="secondary" onPress={onCloseFinal} endContent={<CheckCircle color="#333" />}>
                  Got submitted to Strom for review
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal size={'4xl'} isOpen={showSaveModal} onClose={() => setShowSaveModal(false)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Unsaved changes</ModalHeader>
              <ModalBody>
                <p>It looks like you have been editing something.</p>
                <p>If you leave before saving, your changes will be lost.</p>
                <ScrollShadow className="flex-2 mb-3" style={{ maxHeight: 250 }} size={1} id="msgs">
                  <ReactDiffViewer
                    useDarkTheme
                    oldValue={stepList?.[visibleStep].content}
                    newValue={codeChange}
                    splitView={true}
                  />
                </ScrollShadow>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={() => {
                    setShowSaveModal(false)
                    setVisibleStep(nextStep)
                  }}
                >
                  Discard Changes
                </Button>
                <Button
                  color="secondary"
                  startContent={<Save />}
                  size="md"
                  radius="md"
                  onClick={() => {
                    saveCodeChange()
                  }}
                >
                  Save changes
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

interface ITranscription {
  text: string
  source: string
  isNew: boolean
}

interface IInsight {
  type: string
  content: string
}

interface IImplementation {
  title: string
  description: string
}
