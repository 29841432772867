import React, { createContext, useContext, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'

type Props = {
  children: React.ReactNode
}

type WorksapceContextType = {
  activeWorkspace: string
  setActiveWorkspace?: (value: string) => void
}

export const WorkspaceContext = createContext<WorksapceContextType>({ activeWorkspace: '' })

export const WorkspaceProvider = ({ children }: Props) => {
  const [activeWorkspace, setActiveWorkspace] = useState<string>(localStorage.getItem('activeWorkspace') || '')
  useEffect(() => {
    localStorage.setItem('activeWorkspace', activeWorkspace)
  }, [activeWorkspace])
  return (
    <WorkspaceContext.Provider
      value={{
        activeWorkspace,
        setActiveWorkspace,
      }}
    >
      <div>
        <Toaster />
      </div>
      {children}
    </WorkspaceContext.Provider>
  )
}
export const useWorkspaceContextData = () => useContext(WorkspaceContext)
