import CodeMirror, { EditorState } from '@uiw/react-codemirror'
import { vscodeDark } from '@uiw/codemirror-theme-vscode'
import { javascript } from '@codemirror/lang-javascript'
import { html } from '@codemirror/lang-html'
import { css } from '@codemirror/lang-css'
import { markdown } from '@codemirror/lang-markdown'
import { EditorView } from '@codemirror/view'
import { useState } from 'react'

const editorStyles = EditorView.theme(
  {
    '&': {
      color: 'white',
      backgroundColor: '#034',
      fontSize: '14px',
      borderRadius: '10px'
    },
    '&.cm-editor': {
      flex: '1',
      height: '100%',
      minHeight: '100px',
      position: 'relative',
    },
    '.cm-scroller': {
      position: 'absolute !important',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      'overflow-y': 'auto',
      borderRadius: '10px',
    },
    '&.cm-editor.cm-focused': {
      outline: 'none',
    },
    '.cm-content': {
      caretColor: '#0e9',
    },
    '&.cm-focused .cm-cursor': {
      borderLeftColor: '#0e9',
    },
    '&.cm-focused .cm-selectionBackground, ::selection': {
      backgroundColor: '#074',
      color: '#fff',
    },
    '.cm-gutters': {
      backgroundColor: '#045',
      color: '#ddd',
      border: 'none',
      borderRadius: '10px 0 0 10px',
    },
  },
  { dark: true },
)

export const CodeEditor = ({ code, onCodeChange }: { code: string; onCodeChange?: (code: string) => void }) => {
  const [r, setR] = useState<string>(code)
  return (
    <CodeMirror
      className="flex grow pb-2"
      defaultValue={code}
      value={r}
      onChange={(val, _) => {
        setR(val)
        onCodeChange?.(val)
      }}
      theme={vscodeDark}
      extensions={[
        javascript({ jsx: true, typescript: true }),
        markdown(),
        html(),
        css(),
        editorStyles,
        EditorView.lineWrapping,
        EditorState.readOnly.of(false),
      ]}
      editable={true}
    />
  )
}
