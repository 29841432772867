import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'

export const LANGUAGES = ['en', 'de']

i18next
  // .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      console.log(`i18n ${language} ${namespace}`)
      return import(`./locales/${language}/${namespace}.json`)
    }),
  )
  .init({
    debug: true,
    fallbackLng: {
      'en-US': ['en'],
      'en-GB': ['en'],
      'de-DE': ['de'],
      'de-AT': ['de'],
      'de-CH': ['de'],
      default: ['en'],
    },
  })
