import { useEffect, useRef, useState } from 'react'

import Markdown from 'react-markdown'

import { Icon } from '@iconify/react'
import { Button } from '@nextui-org/button'
import { Textarea, ScrollShadow } from '@nextui-org/react'
import { Sparkles } from 'lucide-react'
import { useParams } from 'react-router-dom'
import api from 'src/lib/api'

import PromptInputWithBottomActions, { PromptInputWithBottomActionsRef } from './prompt-input-with-bottom-actions'
import MessageCard from './message-card'

export function StromSalesforceAgent({
  generateSteps,
  meeting,
  setLoading,
}: {
  generateSteps: (summary: string) => Promise<void>
  meeting: any
  setLoading: (value: boolean) => void
}) {
  const [initialized, isInitialized] = useState<boolean>(false)
  const [thinking, setThinking] = useState<boolean>(false)
  const [allMsgs, setAllMsgs] = useState<ITranscriptionMessage[]>([])
  const [newMsg, setNewMsg] = useState<string>('')
  const scrollPane = useRef(null)
  const [editLastMessage, setEditLastMessage] = useState<boolean>(false)
  const [editedMessage, setEditedMessage] = useState<string>('')
  const { meetingUUID } = useParams()
  const inputRef = useRef<HTMLInputElement>(null)
  const promptInputRef = useRef<PromptInputWithBottomActionsRef>(null)
  // Scrolling to an end is not supported by NextUI but this function does the trick
  const scrollToEnd = () => {
    setTimeout(() => {
      scrollPane?.current?.scrollIntoView && scrollPane.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, 200)
  }

  // Kick if off
  const initialize = async () => {
    const prevDiscussion =
      meeting?.transcription?.conversation?.map((item: any) => ({
        source: item.source === 'system' || item.source === 'assistant' ? 'ai' : 'client',
        value: item.text,
        date: item.date,
      })) ?? []
    console.log(prevDiscussion)
    const lastSummary = meeting?.summary
    if (lastSummary && [...prevDiscussion].pop()?.source !== 'ai') {
      prevDiscussion.push({
        source: 'ai',
        value: lastSummary,
      })
    }
    setAllMsgs(prevDiscussion)
    isInitialized(true) // wait till we have an api response to avoid flickering
    scrollToEnd()
    console.log('AI Consultant loaded')
  }

  useEffect(() => {
    scrollToEnd()
  }, [allMsgs])

  const getUpdate = (msg?: string) => {
    if (newMsg || msg) {
      setLoading(true)
      setThinking(true)
      setAllMsgs((prevTranscription) => [...prevTranscription, { source: 'client', value: msg ?? newMsg }])
      api
        .post(`/meetings/${meetingUUID}/enhance`, {
          question: msg ?? newMsg,
        })
        .then((res) => {
          if (inputRef.current) {
            inputRef.current.value = ''
          }
          setThinking(false)
          setAllMsgs((prevTranscription) => [...prevTranscription, { source: 'ai', value: res.data?.text }])
          if (res.data?.text?.startsWith('```markdown\n# Summary')) {
            startGenerating(res.data?.text)
          } else {
            setLoading(false)
          }
          scrollToEnd()
        })
        .catch((err) => {
          console.log(err)
          setThinking(false)
          setLoading(false)
        })
    }
  }

  const startGenerating = async (summary?: string) => {
    setLoading(true)
    const lastSummary = allMsgs.filter((item) => item.source === 'ai').pop()
    setThinking(true)
    await generateSteps(summary || lastSummary?.value || '')
    setThinking(false)
    setLoading(false)
  }

  useEffect(() => {
    initialize() // in an extra function because async/await is not working in useEffect
  }, [meetingUUID])
  const processMarkDownMessage = (msg: string) => {
    return msg.startsWith('```markdown')
      ? msg
          ?.split('\n')
          ?.slice(1, msg?.split('\n').length - 1)
          .join('\n')
          ?.split('@ANSWER')[0]
      : msg
  }
  const extractAnswers = (msg: string) => {
    if (msg.indexOf('@ANSWER') > -1) {
      // Create a regular expression that captures everything between @ANSWERX@ and @END_ANSWERX@, where X is a number.
      const regex = /@ANSWER(\d+)@([\s\S]*?)@END_ANSWER\1@/g
      let match
      const results = []
      while ((match = regex.exec(msg)) !== null) {
        const answerNumber = match[1]
        const answerText = match[2].trim()
        results.push({ answerNumber, answerText })
      }
      return results
    }
    return []
  }
  return (
    <>
      {initialized && (
        <div className="flex flex-col grow min-h-0">
          <ScrollShadow className="flex-2 mb-3" hideScrollBar size={100} id="msgs">
            <div ref={scrollPane}>
              {allMsgs?.map((msg: ITranscriptionMessage, index: number) => (
                <div className="my-2" key={index}>
                  {editLastMessage && index === allMsgs?.length - 1 ? (
                    <>
                      <Textarea
                        minRows={1}
                        maxRows={200}
                        onChange={(e) => setEditedMessage(e.target.value)}
                        defaultValue={msg.value
                          ?.split('\n')
                          ?.slice(1, msg.value?.split('\n').length - 1)
                          .join('\n')}
                        className="py-0"
                        color={msg.source == 'ai' ? 'default' : 'default'}
                      />
                    </>
                  ) : msg.source === 'ai' ? (
                    <div className={'relative rounded-medium border-small border-divider p-3 flex-wrap'}>
                      <Markdown className={`reactMarkDown flex-wrap no-scrollbar`}>
                        {processMarkDownMessage(msg.value)}
                      </Markdown>
                      {extractAnswers(msg.value).map((item) => (
                        <Button
                          key={`answer_${item.answerNumber}_question_${index}`}
                          color="secondary"
                          className="mt-2 p-4 w-full flex-wrap break-words whitespace-normal"
                          onClick={() => {
                            getUpdate(item.answerText)
                          }}
                          isDisabled={thinking}
                          isLoading={thinking}
                          size="md"
                          radius="md"
                          variant={
                            index + 1 < allMsgs.length - 1 && allMsgs[index + 1]?.value === item.answerText
                              ? 'solid'
                              : 'bordered'
                          }
                          style={{
                            height: 'auto',
                            padding: '10px',
                            textAlign: 'left'
                          }}
                        >
                          {item.answerText}
                        </Button>
                      ))}
                      {msg.value?.startsWith('```markdown\n# Summary') && (
                        <Button
                          color="secondary"
                          className="mt-2 w-full"
                          onClick={() => startGenerating(msg.value)}
                          startContent={thinking ? '' : <Sparkles />}
                          isDisabled={thinking || meeting?.summary?.startsWith('```markdown\n# Question')}
                          isLoading={thinking}
                          size="md"
                          radius="md"
                          style={{}}
                        >
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          {thinking ? 'Generating ...' : meeting?.steps?.length > 0 ? 'Regenerate' : 'Generate'}
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className={`chat ${msg.source == 'ai' ? 'ml-0' : 'mr-0'}`} key={msg.id}>
                      <MessageCard
                        key={index}
                        // attempts={index === 1 ? 2 : 1}
                        avatar={msg.source === 'ai' ? 'Strom' : 'You'}
                        currentAttempt={index === 1 ? 2 : 1}
                        message={msg.value}
                        messageClassName={msg.source === 'client' ? 'bg-content3 text-content3-foreground' : ''}
                        showFeedback={msg.source === 'ai'}
                      />
                    </div>
                  )}
                  {index == allMsgs.length - 1 && msg.source === 'ai' && (
                    <div className="flex">
                      <Button isIconOnly radius="full" size="sm" variant="light">
                        <Icon className="text-lg text-default-600" icon="gravity-ui:copy" />
                      </Button>
                      <Button
                        isIconOnly
                        radius="full"
                        size="sm"
                        variant="light"
                        onClick={() => {
                          if (editLastMessage) {
                            const newAllMsg = [...allMsgs]
                            newAllMsg[index] = {
                              source: 'ai',
                              value: '```markdown+\n' + editedMessage + '\n```',
                            }
                            setAllMsgs(newAllMsg)
                            setEditLastMessage(false)
                          } else {
                            setEditedMessage(
                              msg.value
                                ?.split('\n')
                                ?.slice(1, msg.value?.split('\n').length - 1)
                                .join('\n'),
                            )
                            setEditLastMessage(true)
                          }
                        }}
                      >
                        {editLastMessage ? (
                          <Icon className="text-lg text-default-600" icon="gravity-ui:check" />
                        ) : (
                          <Icon className="text-lg text-default-600" icon="gravity-ui:pencil-to-square" />
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </ScrollShadow>
          <div className="flex flex-col flex-1 justify-end">
            <PromptInputWithBottomActions
              inputRef={inputRef}
              ref={promptInputRef}
              onChange={(e) => {
                setNewMsg(e.target.value) /* console.log(e.target.value); */
              }}
            />
            {/* <Textarea
              placeholder={allMsgs?.length ? 'Change or add ...' : 'Create a new ...'}
              value={newMsg}
              onChange={(e) => setNewMsg(e.target.value)}
              className=""
              style={{ backgroundColor: 'transparent' }}
              disabled={thinking}
            />
            <Button
              color="secondary"
              className="mt-3"
              startContent={<Sparkles />}
              onClick={getUpdate}
              disabled={thinking}
              size="lg"
            >
              {thinking ? 'Thinking ...' : allMsgs?.length ? 'Refine' : 'Generate'}
            </Button> */}
            <Button
              color="secondary"
              variant={'bordered'}
              className="mt-2"
              onClick={() => {
                getUpdate()
                promptInputRef.current?.resetPrompt?.()
              }}
              startContent={thinking ? '' : <Sparkles />}
              isDisabled={thinking}
              isLoading={thinking}
              size="md"
              radius="md"
            >
              {thinking ? 'Thinking' : 'Send'}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

interface ITranscriptionMessage {
  value: string
  source: 'ai' | 'client'
}
