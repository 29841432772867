import { Button, Image, Badge } from '@nextui-org/react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Save, Command } from 'lucide-react'

const version = 'v1.2'
const path = `/download/setup/${version}/`
const win = path + 'Strom-Installer.zip'
const mac = path + 'Strom-Installer.pkg'

export default function Download() {
  useEffect(() => {
    // window.location.href = asset
  }, [])

  return (
    <>
      <Helmet>
        <title>Download Strom Desktop App</title>
      </Helmet>

      <div className="flex flex-grow justify-center">
        <div className="flex flex-col items-center place-content-center">
          <Image src="/img/strom-ai-logo.png" alt="Strom Desktop App" width={60} />
          <p className="text-2xl text-center font-semibold mb-2 mt-5">Strom Desktop App </p>
          <p>Version {version}</p>

          <div className="flex w-80 mt-6 justify-between">
            <a href={win} download>
              <Button href={win} color="secondary" variant="bordered" radius="full" startContent={<Save />}>
                Windows
              </Button>
            </a>

            <Badge content="new" variant="faded">
              <a href={mac} download>
                <Button href={mac} color="secondary" variant="bordered" radius="full" startContent={<Command />}>
                  Mac OSX
                </Button>
              </a>
            </Badge>
          </div>
        </div>
      </div>
    </>
  )
}
