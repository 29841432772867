import { useEffect, useRef, useState } from 'react'
import api from 'src/lib/api'
import sampleMsgs from 'src/data/sample/msgs.json'

import Markdown from 'react-markdown'

import { Button } from '@nextui-org/button'
import { Card, CardHeader, CardBody, CardFooter, Divider, Link, Image } from '@nextui-org/react'
import { Textarea, Input, ScrollShadow, Chip } from '@nextui-org/react'
import { Sparkles, Wand } from 'lucide-react'
import { CodeEditor } from './code-editor'
import { useParams } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'

export function StromConsultant({ rendering, triggerGenerate }: { rendering: boolean; triggerGenerate: any }) {
  const [initialized, isInitialized] = useState<boolean>(false)
  const [thinking, isThinking] = useState<boolean>(false)
  const [thinkingJob, runThinkingJob] = useState<NodeJS.Timeout>()

  const [allMsgs, setAllMsgs] = useState<[any]>([]) // old msgs from thread
  const [newMsg, setNewMsg] = useState<string>('') // new msg in input field
  const [summaryShown, setSummaryShown] = useState<string>('')

  const scrollPane = useRef(null)

  const { client, project } = useParams()

  const setExampleMsg = (msg: string) => {
    setNewMsg(msg)
    Mixpanel.btn('ai', 'message_example', { client: client, project: project, msg: msg })
  }

  const setThinking = (val: boolean) => {
    console.log('isThinking ' + val)
    isThinking(val)
  }

  // Load old msgs from the thread
  const loadMsgs = async () => {
    console.log('loadMsgs')
    Mixpanel.act('ai', 'loading', { client: client, project: project })
    return api
      .get(`/consult/${project}/msgs`)
      .then((res) => {
        if (res.data && res.data[0] && res.data[0].id) {
          console.log('loadMsgs successful', res.data)
          showAllMsgs(res.data)
          Mixpanel.act('ai', 'loading_done', { client: client, project: project })
        } else {
          console.log('loadMsgs failed', res.data)
          Mixpanel.err('ai', 'loading_done', 'no messages', { client: client, project: project })
        }
      })
      .catch((err) => {
        console.error('loadMsgs', err.message, err.response?.data)
        Mixpanel.err('ai', 'loading_done', err.message, { client: client, project: project })
      })
  }

  // Process and show all msgs from the thread
  const showAllMsgs = (msgs: any) => {
    console.log('showAllMsgs', msgs.length)
    const conversation = msgs.filter((m) => m.agent != 'system')
    console.log('showAllMsgs human conversation', conversation.length)

    const sorted = conversation.sort((a, b) => a.time - b.time)
    console.log('showAllMsgs last 0', sorted[0].content)

    const summaries = sorted.filter((m) => m.format == 'markdown')
    console.log('showAllMsgs summaries', summaries.length)

    if (summaries[summaries.length - 1] && summaries[summaries.length - 1].id) {
      console.log('showAllMsgs summary', summaries[summaries.length - 1].content)
      setSummaryShown(summaries[summaries.length - 1].id) // set the last summary to be visible, all others will be hidden
    }
    setAllMsgs(sorted)
  }

  // Load old summary from the thread
  // const loadSummary = async () => {
  //   console.log('loadSummary')
  //   return api
  //     .get(`/develop/summary`)
  //     .then((res) => {
  //       console.log('loadSummary', res.data)
  //       if (res.data?.content) {
  //         setSummary(res.data?.content)
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('loadSummary', err.message, err.response?.data)
  //     })
  // }

  // User types new message
  const newMsgTyping = async (e: any) => {
    console.log('newMsgChange', e.target.value)
    setNewMsg(e.target.value)
  }

  // Send a new message
  const sendMsg = async () => {
    console.log('sendMsg', newMsg)
    setThinking(true)
    Mixpanel.btn('ai', 'message', { client: client, project: project, msg: newMsg })
    return api
      .post(`/consult/${project}/msg`, {
        content: newMsg,
      })
      .then((res) => {
        console.log('sendMsg', res.data)
        addMsg(res.data)
        startThinking()
        Mixpanel.act('ai', 'thinking', { client: client, project: project })
      })
      .catch((err) => {
        console.error('sendMsg', err.message, err.response.data)
        Mixpanel.err('ai', 'thinking', err.message, { client: client, project: project })
      })
      .finally(() => {
        // setThinking(false)
      })
  }

  // Request summary
  const triggerSummary = async () => {
    console.log('triggerSummary', newMsg)
    setThinking(true)
    Mixpanel.act('ai', 'summary', { client: client, project: project })
    return api
      .post(`/consult/${project}/summary`)
      .then((res) => {
        console.log('triggerSummary', res.data)
        startThinking()
      })
      .catch((err) => {
        console.error('triggerSummary', err.response.data)
        Mixpanel.err('ai', 'summary_done', err.message, { client: client, project: project })
      })
      .finally(() => {
        setThinking(false)
      })
  }

  // There are multiple summaries in the thread so we hide them, but let the user toggle them
  const toggleSummary = (msgId: string) => {
    setSummaryShown((previous) => (previous == msgId ? 0 : msgId))
    Mixpanel.btn('ai', 'summary_toggle', { client: client, project: project })
  }

  // Render new message (add the well-structured message from data from the server to the UI)
  const addMsg = async (msg: any) => {
    msg.new_in_session = true
    setAllMsgs((prevMsgs) => [...prevMsgs, msg]) // when the setter gets a function the previous value is given
    setNewMsg('') // empty the text input
    scrollToEnd()
    // setThinking(false) // got an answer from the assistant
  }

  // Scrolling to an end is not supported by NextUI but this function does the trick
  const scrollToEnd = () => {
    setTimeout(() => {
      console.log(scrollPane.current)
      scrollPane?.current?.scrollIntoView && scrollPane.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, 200)
  }

  // Think while conversation is checked
  const startThinking = async () => {
    clearTimeout(thinkingJob)
    setThinking(true)
    runThinkingJob(
      setTimeout(async () => {
        console.log('checkConversation')
        await checkConversation()
      }, 500),
    )
    // Mixpanel.act('ai', 'thinking', { client: client, project: project }) // would be triggered multiple times
  }

  // Wait for the AI to have an answer ready
  const checkConversation = async () => {
    return api
      .get(`/consult/${project}/check`)
      .then((res) => {
        console.log('checkConversation', res.data)

        if (res.data?.status == 'thinking') {
          startThinking()
        } else if (res.data?.status == 'complete') {
          const msg = res.data
          if (msg.readiness) triggerSummary() // Continue with summary
          else if (msg.format == 'markdown') {
            triggerGenerate() // Continue with code generation
            Mixpanel.act('ai', 'summary_done', { client: client, project: project })
          } else if (msg.agent == 'assistant' && !msg.readiness) {
            setThinking(false) // Got an answer
            Mixpanel.act('ai', 'thinking_done', { client: client, project: project })
          }
          addMsg(msg)
        } else {
          console.error('checkConversation', res.data)
          Mixpanel.err('ai', 'thinking_done', 'unknown status', { client: client, project: project })
        }
      })
      .catch((err) => {
        console.error('checkConversation', err.message, err.response.data)
        Mixpanel.err('ai', 'thinking_done', err.message, { client: client, project: project })
      })
      .finally(() => {
        // setThinking(false)
      })
  }

  // Use sample data if connection is broken
  const checkConnection = () => {
    return api
      .get(`/ping`)
      .then((res) => console.log('checkConnection success'))
      .catch((err) => {
        console.log('checkConnection error (use sample data)')
        showAllMsgs(sampleMsgs)
        // setSummary(sampleSummary.content)
        isInitialized(true)
      })
  }

  // Kick if off
  const initialize = async () => {
    console.log('AI Consultant loading')
    checkConnection()
    await loadMsgs()
    // await loadSummary()
    isInitialized(true) // wait till we have an api response to avoid flickering
    scrollToEnd()
    console.log('AI Consultant loaded')
  }

  useEffect(() => {
    console.log('AI Consultant is here')
    initialize() // in an extra function because async/await is not working in useEffect
  }, [project])

  return (
    <>
      {initialized && (
        <>
          <Card className="flex flex-grow" style={{ maxHeight: 'calc(100vh - 154px)' }}>
            <CardHeader className="flex gap-3">
              <Image
                alt="nextui logo"
                height={40}
                radius="sm"
                src="/img/strom-ai-logo.png"
                width={40}
                onClick={triggerGenerate}
              />
              <div className="flex flex-col">
                <p className="text-lg">Strom AI Assistant ({allMsgs?.length})</p>
              </div>
            </CardHeader>
            <Divider />
            <CardBody className="flex">
              <ScrollShadow
                className={'flex-2 mb-3 ' + (allMsgs.length ? '' : 'hidden')}
                hideScrollBar
                size={100}
                id="msgs"
              >
                <div ref={scrollPane}>
                  {allMsgs?.map(
                    (msg: any) =>
                      (msg?.format == 'text' && (
                        <div className={`chat ${msg.agent == 'assistant' ? 'ml-6' : 'mr-6'}`} key={msg.id}>
                          <Textarea
                            isReadOnly
                            label={msg.agent == 'assistant' ? 'Strom' : 'You'}
                            variant="bordered"
                            labelPlacement="inside"
                            minRows="1"
                            defaultValue={msg.content}
                            className="py-1"
                            color={msg.agent == 'assistant' ? 'success' : ''}
                          />
                        </div>
                      )) ||
                      (msg?.format == 'markdown' && (
                        <div
                          className={`flex flex-col place-items-center mt-1 summary ${
                            msg.new_in_session ? 'new' : 'old'
                          }`}
                          key={msg.id}
                          onClick={() => toggleSummary(msg.id)}
                        >
                          <Chip
                            color="secondary"
                            startContent={<Sparkles color="white" size={18} />}
                            variant="shadow"
                            size="lg"
                            className="mb-1 cursor-pointer"
                            classNames={{
                              base: 'bg-gradient-to-br from-green-500 to-cyan-500 border-small border-white/50 shadow-cyan-500/30',
                              content: 'drop-shadow shadow-black text-white',
                            }}
                          >
                            New user story created {summaryShown == msg.id ? '▲' : '▼'}
                          </Chip>
                          <Markdown
                            children={msg.content}
                            className={`reactMarkDown ${
                              summaryShown == msg.id ? 'visible' : 'hidden'
                            } border-2 rounded-xl border-secondary p-3 mt-1`}
                          />
                        </div>
                      )),
                  )}
                </div>
              </ScrollShadow>
              <div className="flex flex-col flex-1">
                <Textarea
                  placeholder={allMsgs?.length ? 'Change or add ...' : 'Create a new ...'}
                  value={newMsg}
                  onChange={newMsgTyping}
                  className=""
                  style={{ backgroundColor: 'transparent' }}
                  disabled={thinking || rendering}
                />
                <Button
                  color="secondary"
                  className="mt-3"
                  startContent={<Sparkles />}
                  onClick={sendMsg}
                  disabled={thinking || rendering}
                  size="lg"
                >
                  {thinking
                    ? 'Thinking ...'
                    : rendering
                    ? 'Rendering preview ...'
                    : allMsgs?.length
                    ? 'Refine'
                    : 'Generate'}
                </Button>
                {/* {allMsgs?.length > 8 && (
                  <Button
                    color="secondary"
                    className="mt-3"
                    endContent={<Wand />}
                    onClick={triggerSummary}
                    disabled={thinking || rendering}
                    size="lg"
                  >
                    Let's see some magic
                  </Button>
                )} */}
              </div>
            </CardBody>
            {
              // show examples if there are no old messages and no new message in the input field
              !allMsgs?.length && !newMsg.length && (
                <>
                  <CardFooter className="flex flex-col gap-2">
                    <p className="pt-4 px-4 text-base text-secondary">Need help? Try these prompts:</p>
                    <Card
                      className="w-full bg-black/100"
                      isPressable
                      onClick={() => setExampleMsg('Line chart for the last quarter')}
                    >
                      <CardHeader className="flex gap-3">
                        <Image alt="nextui logo" width={60} height={60} src="/img/sidebarai-line-chart.png" />
                        <div className="flex flex-col">
                          <p className="text-md">
                            <span className="text-secondary">Line chart</span> for the last quarter
                          </p>
                        </div>
                      </CardHeader>
                    </Card>
                    <Card
                      className="w-full bg-black/100"
                      isPressable
                      onClick={() => setExampleMsg('Pie chart for the last quarter')}
                    >
                      <CardHeader className="flex gap-3">
                        <Image alt="nextui logo" width={60} height={60} src="/img/sidebarai-pie-chart.png" />
                        <div className="flex flex-col">
                          <p className="text-md">
                            <span className="text-secondary">Pie chart</span> for the last quarter
                          </p>
                        </div>
                      </CardHeader>
                    </Card>
                    <Card
                      className="w-full bg-black/100"
                      isPressable
                      onClick={() => setExampleMsg('Scatter plot for the last quarter')}
                    >
                      <CardHeader className="flex gap-3">
                        <Image alt="nextui logo" width={60} height={60} src="/img/sidebarai-scatter-plot.png" />
                        <div className="flex flex-col">
                          <p className="text-md">
                            <span className="text-secondary">Scatter plot</span> for the last quarter
                          </p>
                        </div>
                      </CardHeader>
                    </Card>
                  </CardFooter>
                </>
              )
            }
          </Card>
          {/* {summary && (
            <div className="">
              <Markdown children={summary} className="reactMarkDown" />
              <Button color="secondary" className="mt-3" onClick={triggerGenerate} disabled={thinking || rendering}>
                Generate Code
              </Button>
            </div>
          )} */}
        </>
      )}
    </>
  )
}
