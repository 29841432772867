import { BubbleMenu } from '@tiptap/react'
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  ChevronsUpDownIcon,
  ItalicIcon,
  ListIcon,
  UnderlineIcon,
  WandIcon,
} from 'lucide-react'
import React from 'react'
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection, Button, Spinner } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useSocketContextData } from 'src/context/socket'

import Mixpanel from 'src/lib/mixpanel'

export default function SmartTextMenu({ editor, ursUUID }: any) {
  const [openAiMenu, setOpenAiMenu] = React.useState<boolean>(false)
  const { io } = useSocketContextData()
  const menuitems = [
    {
      category: 'basics',
      title: 'Basic adjustments',
      items: [
        {
          key: 'improveClarity',
          label: 'Improve clarity',
          icon: 'lucide:arrow-down-to-dot',
        },
        {
          key: 'makeItLonger',
          label: 'Make longer',
          icon: 'carbon:text-long-paragraph',
        },
        {
          key: 'makeItShorter',
          label: 'Make shorter',
          icon: 'carbon:text-short-paragraph',
        },
        {
          key: 'grammarCheck',
          label: 'Check grammar & style',
          icon: 'lucide:spell-check',
        },
        {
          key: 'paraphrase',
          label: 'Paraphrase',
          icon: 'lucide:arrow-left-right',
        }
      ]
    },
    {
      category: 'tone',
      title: 'Change tone',
      items: [
        {
          key: 'professional',
          label: 'Professional but approachable',
          icon: 'lucide:shirt',
        },
        {
          key: 'creativeEngaging',
          label: 'Creative & engaging',
          icon: 'lucide:drama',
        },
        {
          key: 'formalBusiness',
          label: 'Formal but friendly',
          icon: 'lucide:briefcase-business',
        },
        {
          key: 'persuasive',
          label: 'Persuasive & confident',
          icon: 'lucide:message-circle-warning',
        },
      ]
    }
  ];

  const [aiWorking, setAIWorking] = React.useState<boolean>(false)

  // Function to track smart text menu opening
  const handleMenuOpen = () => {
    Mixpanel.track("Smart text menu opened", {
      "location": "Text Editor"
    });
  };

  const processSelectedText = async (operationType: string) => {
    if (editor && ursUUID) {
      setAIWorking(true)
      const { state, dispatch } = editor.view
      const { selection, doc } = state
      const selectedText = doc.textBetween(selection.from, selection.to, ' ')
      const result = await io.emitWithAck('textProcess', {
        selectedText,
        content: doc.textContent,
        token: localStorage.getItem('accessToken'),
        operation: operationType,
      });

      // Track menu item click
      Mixpanel.track("Smart text menu AI item clicked", {
        "operation": operationType,
        "selected_text_length": selectedText.length,
        "location": "Text Editor"
      });

      if (result.text) {
        const tr = state.tr.replaceWith(selection.from, selection.to, state.schema.text(result.text))
        dispatch(tr);
      }
      setAIWorking(false);
    }
  }

  return (
    <BubbleMenu
      className="bubble-menu cursor-pointer"
      tippyOptions={{ onShow: handleMenuOpen }} // Track smart text menu opening
      editor={editor}
    >
      <div
        className={
          'rounded-xl border border-1 border-default-300 bg-white flex flex-row p-1 shadow card gap-2 text-default-400 w-max'
        }
      >
        <div className={'flex flex-row cursor-pointer justify-center items-center'}>
          <Dropdown
            classNames={{
              content: 'rounded-xl border border-1 border-default-300',
            }}
          >
            <DropdownTrigger>
              <Button variant="light" isDisabled={aiWorking}>
                {aiWorking ? (
                  <>
                    <div className={'flex flex-row gap-1 w-max justify-center items-center'}>
                      <Spinner size={'sm'} />
                      <span className={'text-dark'}>Strom AI is processing</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={'flex flex-row gap-2 w-max justify-center items-center'} id="smart-text-menu-ai-item">
                      <Icon icon="lucide:sparkles" width={20} />
                      <span className={'text-dark'}>Edit with AI</span>
                      <Icon icon="lucide:chevrons-up-down" />
                    </div>
                  </>
                )}
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Dynamic Actions">
              {menuitems.map(({ category, title, items }) => (
                <>
                  <DropdownSection title={title}>
                    {items.map((item) => (
                      <DropdownItem key={item.key} onClick={() => processSelectedText(item.key)}>
                        <span className="flex flex-row w-full font-normal items-center gap-2">
                          <Icon icon={item.icon} className="text-secondary" width={20} />
                          {item.label}
                        </span>
                      </DropdownItem>
                    ))}
                  </DropdownSection>
                </>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className={'w-[1px] bg-default-200'} />
        <div className={'flex flex-row gap-1 justify-center items-center'}>
          <div
            className={`p-2 rounded ${
              editor.isActive('bold') ? 'text-black bg-default-100' : 'bg-white text-black'
            }`}
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            <Icon icon="lucide:bold" width={20} />
          </div>
          <div
            className={`p-2 rounded ${
              editor.isActive('italic') ? 'text-black bg-default-100' : 'bg-white text-black'
            }`}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            <Icon icon="lucide:italic" width={20} />
          </div>
          <div
            className={`p-2 rounded ${
              editor.isActive('underline') ? 'text-black bg-default-100' : 'bg-white text-black'
            }`}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          >
            <Icon icon="lucide:underline" width={20} />
          </div>
        </div>
        <div className={'w-[1px] bg-default-200'} />
        <div className={'flex flex-row gap-1 justify-center items-center'}>
          <div
            className={`p-1 bg-white rounded ${
              editor.isActive('bold') ? 'text-black bg-default-100' : 'bg-white text-black'
            }`}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          >
            <Icon icon="lucide:align-left" width={20} />
          </div>
          <div
            className={`p-1 bg-white rounded ${
              editor.isActive('bold') ? 'text-black bg-default-100' : 'bg-white text-black'
            }`}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          >
            <Icon icon="lucide:align-center" width={20} />
          </div>
          <div
            className={`p-1 bg-white rounded ${
              editor.isActive('bold') ? 'text-black bg-default-100' : 'bg-white text-black'
            }`}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          >
            <Icon icon="lucide:align-right" width={20} />
          </div>
        </div>
        <div className={'w-[1px] bg-default-200'} />
        <div className={'flex flex-row gap-1 justify-center items-center'}>
          <div
            className={`p-1 rounded ${
              editor.isActive('bulletList') ? 'text-black bg-default-100' : 'bg-white text-black'
            }`}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          >
            <Icon icon="lucide:list" width={20} />
          </div>
        </div>
      </div>
    </BubbleMenu>
  )
}
