import { Chip } from '@nextui-org/react'
import { Icon } from '@iconify/react'

import { type SidebarItem, SidebarItemType } from '../sidebar'
import TeamAvatar from '../sidebar/team-avatar'

/**
 * Please check the https://nextui.org/docs/guide/routing to have a seamless router integration
 */

export const items: SidebarItem[] = [
  {
    key: 'overview',
    title: 'Overview',
    items: [
      {
        key: 'home',
        link: '/',
        icon: 'solar:home-2-linear',
        title: 'Home',
      },
      {
        key: 'urs',
        link: '/urs',
        icon: 'solar:documents-minimalistic-linear',
        title: 'Projects',
      },
    ],
  },
  // {
  //   key: 'salesforce',
  //   title: 'Salesforce',
  //   items: [
  //     {
  //       key: 'instances',
  //       link: '/instances',
  //       icon: 'solar:layers-linear',
  //       title: 'SFDC instances',
  //     },
  //     {
  //       key: 'projects',
  //       link: '/projects',
  //       icon: 'solar:widget-2-outline',
  //       title: 'SFDC projects',
  //     },
  //   ]
  // }, 
  {
    key: 'organziation',
    title: 'Organization',
    items: [
      {
        key: 'team',
        link: '/team',
        icon: 'solar:users-group-two-rounded-outline',
        title: 'Team',
        endContent: (
          <Chip size="sm" variant="flat">
            New
          </Chip>
        ),
      },
      {
        key: 'audit',
        link: '/audit',
        icon: 'solar:file-text-outline',
        title: 'Audit logs',
        endContent: (
          <Chip size="sm" variant="flat">
            Soon
          </Chip>
        ),
      },
      {
        key: 'settings',
        link: '/settings',
        icon: 'solar:settings-outline',
        title: 'Settings',
      },
    ],
  },
]

export const sectionItems: SidebarItem[] = [
  {
    key: 'overview',
    title: 'Overview',
    items: [
      {
        key: 'home',
        link: '#',
        icon: 'solar:home-2-linear',
        title: 'Home',
      },
      {
        key: 'projects',
        link: '#',
        icon: 'solar:widget-2-outline',
        title: 'Projects',
        endContent: <Icon className="text-default-400" icon="solar:add-circle-line-duotone" width={24} />,
      },
      {
        key: 'tasks',
        link: '#',
        icon: 'solar:checklist-minimalistic-outline',
        title: 'Tasks',
        endContent: <Icon className="text-default-400" icon="solar:add-circle-line-duotone" width={24} />,
      },
      {
        key: 'team',
        link: '#',
        icon: 'solar:users-group-two-rounded-outline',
        title: 'Team',
      },
      {
        key: 'tracker',
        link: '#',
        icon: 'solar:sort-by-time-linear',
        title: 'Tracker',
        endContent: (
          <Chip size="sm" variant="flat">
            New
          </Chip>
        ),
      },
    ],
  },
  {
    key: 'organization',
    title: 'Organization',
    items: [
      {
        key: 'cap_table',
        link: '#',
        title: 'Cap Table',
        icon: 'solar:pie-chart-2-outline',
        items: [
          {
            key: 'shareholders',
            link: '#',
            title: 'Shareholders',
          },
          {
            key: 'note_holders',
            link: '#',
            title: 'Note Holders',
          },
          {
            key: 'transactions_log',
            link: '#',
            title: 'Transactions Log',
          },
        ],
      },
      {
        key: 'analytics',
        link: '#',
        icon: 'solar:chart-outline',
        title: 'Analytics',
      },
      {
        key: 'perks',
        link: '/perks',
        icon: 'solar:gift-linear',
        title: 'Perks',
        endContent: (
          <Chip size="sm" variant="flat">
            3
          </Chip>
        ),
      },
      {
        key: 'expenses',
        link: '#',
        icon: 'solar:bill-list-outline',
        title: 'Expenses',
      },
      {
        key: 'settings',
        link: '/settings',
        icon: 'solar:settings-outline',
        title: 'Settings',
      },
    ],
  },
]

export const sectionItemsWithTeams: SidebarItem[] = [
  ...sectionItems,
  {
    key: 'your-teams',
    title: 'Your Teams',
    items: [
      {
        key: 'nextui',
        link: '#',
        title: 'NextUI',
        startContent: <TeamAvatar name="Next UI" />,
      },
      {
        key: 'tailwind-variants',
        link: '#',
        title: 'Tailwind Variants',
        startContent: <TeamAvatar name="Tailwind Variants" />,
      },
      {
        key: 'nextui-pro',
        link: '#',
        title: 'NextUI Pro',
        startContent: <TeamAvatar name="NextUI Pro" />,
      },
    ],
  },
]

export const brandItems: SidebarItem[] = [
  {
    key: 'overview',
    title: 'Overview',
    items: [
      {
        key: 'home',
        link: '#',
        icon: 'solar:home-2-linear',
        title: 'Home',
      },
      {
        key: 'projects',
        link: '#',
        icon: 'solar:widget-2-outline',
        title: 'Projects',
        endContent: <Icon className="text-primary-foreground/60" icon="solar:add-circle-line-duotone" width={24} />,
      },
      {
        key: 'tasks',
        link: '#',
        icon: 'solar:checklist-minimalistic-outline',
        title: 'Tasks',
        endContent: <Icon className="text-primary-foreground/60" icon="solar:add-circle-line-duotone" width={24} />,
      },
      {
        key: 'team',
        link: '#',
        icon: 'solar:users-group-two-rounded-outline',
        title: 'Team',
      },
      {
        key: 'tracker',
        link: '#',
        icon: 'solar:sort-by-time-linear',
        title: 'Tracker',
        endContent: (
          <Chip className="bg-primary-foreground font-medium text-primary" size="sm" variant="flat">
            New
          </Chip>
        ),
      },
    ],
  },
  {
    key: 'your-teams',
    title: 'Your Teams',
    items: [
      {
        key: 'nextui',
        link: '#',
        title: 'NextUI',
        startContent: (
          <TeamAvatar
            classNames={{
              base: 'border-1 border-primary-foreground/20',
              name: 'text-primary-foreground/80',
            }}
            name="Next UI"
          />
        ),
      },
      {
        key: 'tailwind-variants',
        link: '#',
        title: 'Tailwind Variants',
        startContent: (
          <TeamAvatar
            classNames={{
              base: 'border-1 border-primary-foreground/20',
              name: 'text-primary-foreground/80',
            }}
            name="Tailwind Variants"
          />
        ),
      },
      {
        key: 'nextui-pro',
        link: '#',
        title: 'NextUI Pro',
        startContent: (
          <TeamAvatar
            classNames={{
              base: 'border-1 border-primary-foreground/20',
              name: 'text-primary-foreground/80',
            }}
            name="NextUI Pro"
          />
        ),
      },
    ],
  },
]

export const sectionLongList: SidebarItem[] = [
  ...sectionItems,
  {
    key: 'payments',
    title: 'Payments',
    items: [
      {
        key: 'payroll',
        link: '#',
        title: 'Payroll',
        icon: 'solar:dollar-minimalistic-linear',
      },
      {
        key: 'invoices',
        link: '#',
        title: 'Invoices',
        icon: 'solar:file-text-linear',
      },
      {
        key: 'billing',
        link: '#',
        title: 'Billing',
        icon: 'solar:card-outline',
      },
      {
        key: 'payment-methods',
        link: '#',
        title: 'Payment Methods',
        icon: 'solar:wallet-money-outline',
      },
      {
        key: 'payouts',
        link: '#',
        title: 'Payouts',
        icon: 'solar:card-transfer-outline',
      },
    ],
  },
  {
    key: 'your-teams',
    title: 'Your Teams',
    items: [
      {
        key: 'nextui',
        link: '#',
        title: 'NextUI',
        startContent: <TeamAvatar name="Next UI" />,
      },
      {
        key: 'tailwind-variants',
        link: '#',
        title: 'Tailwind Variants',
        startContent: <TeamAvatar name="Tailwind Variants" />,
      },
      {
        key: 'nextui-pro',
        link: '#',
        title: 'NextUI Pro',
        startContent: <TeamAvatar name="NextUI Pro" />,
      },
    ],
  },
]

export const sectionNestedItems: SidebarItem[] = [
  {
    key: 'home',
    link: '#',
    icon: 'solar:home-2-linear',
    title: 'Home',
  },
  {
    key: 'project',
    link: '#',
    icon: 'solar:widget-2-outline',
    title: 'All Projects',
    endContent: <Icon className="text-default-400" icon="solar:add-circle-line-duotone" width={24} />,
  },
  {
    key: 'tasks',
    link: '#',
    icon: 'solar:checklist-minimalistic-outline',
    title: 'Tasks',
    endContent: <Icon className="text-default-400" icon="solar:add-circle-line-duotone" width={24} />,
  },
  {
    key: 'team',
    link: '#',
    icon: 'solar:users-group-two-rounded-outline',
    title: 'Team',
  },
  {
    key: 'tracker',
    link: '#',
    icon: 'solar:sort-by-time-linear',
    title: 'Tracker',
    endContent: (
      <Chip size="sm" variant="flat">
        New
      </Chip>
    ),
  },
  {
    key: 'analytics',
    link: '#',
    icon: 'solar:chart-outline',
    title: 'Analytics',
  },
  {
    key: 'perks',
    link: '#',
    icon: 'solar:gift-linear',
    title: 'Perks',
    endContent: (
      <Chip size="sm" variant="flat">
        3
      </Chip>
    ),
  },
  {
    key: 'cap_table',
    title: 'Cap Table',
    icon: 'solar:pie-chart-2-outline',
    type: SidebarItemType.Nest,
    items: [
      {
        key: 'shareholders',
        icon: 'solar:users-group-rounded-linear',
        link: '#',
        title: 'Shareholders',
      },
      {
        key: 'note_holders',
        icon: 'solar:notes-outline',
        link: '#',
        title: 'Note Holders',
      },
      {
        key: 'transactions_log',
        icon: 'solar:clipboard-list-linear',
        link: '#',
        title: 'Transactions Log',
      },
    ],
  },
  {
    key: 'expenses',
    link: '#',
    icon: 'solar:bill-list-outline',
    title: 'Expenses',
  },
]
