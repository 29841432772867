import React, { useState, useEffect } from 'react'
import { Listbox, ListboxItem } from '@nextui-org/react'
import dayjs from 'dayjs'
export default function CountDownProject({ project }: CountDownProjectProps) {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [progress, setProgress] = useState(0)
  const listItems = [
    {
      title: 'Strom AI generates your project',
    },
    {
      title: 'Our team polishes it by hand',
    },
    {
      title: 'Ready in 24 hours, we’ll notify you',
    },
  ]
  const getTime = (deadline: dayjs.Dayjs) => {
    const time = deadline.diff(dayjs(), 'ms')
    setHours(Math.max(0, Math.floor((time / (1000 * 60 * 60)) % 24)))
    setMinutes(Math.max(0, Math.floor((time / 1000 / 60) % 60)))
    setSeconds(Math.max(0, Math.floor((time / 1000) % 60)))
    setProgress(Math.min(100, ((86400 - time / 1000) / 86400) * 100)) // 86400 second per day. we sub time /1000 as time is in ms.
  }

  useEffect(() => {
    if (project && hours === 0 && minutes === 0 && seconds === 0) {
      const deadline = dayjs(project.createdAt).add(1, 'days')
      const interval = setInterval(() => getTime(deadline), 1000)
      return () => clearInterval(interval)
    }
  }, [project])
  return (
    <div className={'flex flex-1 flex-col'}>
      <div
        className={'flex flex-col flex-1 justify-center items-center pt-10 pb-10'}
        style={{
          backgroundImage: 'radial-gradient(50% 50% at 50% 50%, #166A75 0%, #000000 100%)',
        }}
      >
        <span className={'text-medium text-secondary font-default'}>Your project is in progress</span>
        <h1 className={'text-3xl font-medium mt-2 mb-2'}>Ready in 24 hours</h1>
        <span
          className={'text-md text-secondary text-center'}
          style={{
            textShadow: '0px 0px 11px 0px #FFFFFF40',
            color: '#C9E1E6',
            fontWeight: '400',
          }}
        >
          Just wait a little – we’ll get back to you within 24 hours.<br />Our AI and team are polishing it.
        </span>
        <div className={'w-full flex justify-around items-center pl-20 pr-20'}>
          <span
            className={'font-light text-secondary'}
            style={{
              color: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
              fontSize: 64,
              fontWeight: '100',
            }}
          >
            {hours.toString().padStart(2, '0')}
          </span>
          <span
            className={'font-light text-secondary ml-5 mr-5'}
            style={{
              color: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
              fontSize: 64,
              fontWeight: '100',
            }}
          >
            :
          </span>
          <span
            className={'font-light text-secondary'}
            style={{
              color: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
              fontSize: 64,
              fontWeight: '100',
            }}
          >
            {minutes.toString().padStart(2, '0')}
          </span>
          <span
            className={'font-light text-secondary  ml-5 mr-5'}
            style={{
              color: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
              fontSize: 64,
              fontWeight: '100',
            }}
          >
            :
          </span>
          <span
            className={'text-4xl font-light text-secondary'}
            style={{
              color: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
              fontSize: 64,
              fontWeight: '100',
            }}
          >
            {seconds.toString().padStart(2, '0')}
          </span>
        </div>
        <div
          className={'w-full flex border-1 rounded-xl border-secondary ml-5  mr-5 relative mt-10'}
          style={{
            height: 14,
            maxWidth: '80%',
          }}
        >
          <div
            className={'rounded-xl relative progress-bar'}
            style={{
              height: 16,
              background: 'linear-gradient(90deg, #071D21 0%, #65D0CA 100%)',
              width: `${progress}%`,
              marginTop: -2,
              marginLeft: -2,
              overflow: 'visible',
            }}
          ></div>
          <img
            src={'/img/ship.png'}
            alt={'progress ship'}
            className={'progress-bar-img'}
            style={{
              position: 'absolute',
              top: -18,
              left: `${Math.min(80, progress - 10)}%`,
              maxWidth: '100px',
              height: 'auto'
            }}
          />
        </div>
      </div>

      <div className={'flex flex-col min-h-[40%] w-[80%] self-center'}>
        <Listbox hideSelectedIcon aria-label="Onboarding checklist" variant="flat" items={listItems}>
          {listItems.map((item, index) => (
            <ListboxItem
              key={`ai-step-list-${index}`}
              classNames={{
                base: 'w-full px-2 md:px-4 p-2 gap-2 border-1 border-secondary m-1',
                title: 'text-default font-light text-secondary',
                description: 'text-small',
              }}
              startContent={
                <div
                  className="justify-center item-center flex border border-divider bg-secondary"
                  style={{ borderRadius: 30, width: 30, height: 30 }}
                >
                  <span
                    className={
                      'text-medium align-middle font-bold text-default flex flex-1 items-center justify-center'
                    }
                  >
                    {index + 1}
                  </span>
                </div>
              }
              title={item.title}
              description={''}
            />
          ))}
        </Listbox>
      </div>
    </div>
  )
}

interface CountDownProjectProps {
  project: any
}
